import { useEffect, useRef, useState } from 'react';
import {
  Route,
  useHistory,
  useLocation,
  useRouteMatch,
} from 'react-router-dom';
import { internalRootPaths } from 'components/App';
import NothingFound from 'components/NothingFound';
import { CreateGroupDialog } from 'domains/organization/dialogs';
import GroupDetailsPage from 'domains/organization/pages/OrganizationsPage/GroupDetailsPage';
import {
  DataGrid,
  GridSortModel,
  gridUtils,
  LoaderWithOverlay,
  useGridApiRef,
} from 'elements';
import { useShowPageError } from 'hoc/withPageErrorWrapper';
import useIsDetailsPageOpen from 'hooks/useIsDetailsPageOpen';
import useMounted from 'hooks/useMounted';
import useSetQueryParam from 'hooks/useSetQueryParam';
import {
  DEFAULT_PAGE_LIMIT,
  FeOnlyOrganizationGroupType,
  feOnlyOrganizationGroupTypes,
  OrganizationGroupType,
  PartialOrganizationGroup,
  supportedCountries,
  SupportedCountry,
} from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { isSortValid } from 'services/utils';
import Filters from './Filters';
import useColumns from './useColumns';

const OrganizationGroupTypeApiFilterMapping = {
  [FeOnlyOrganizationGroupType.gcc]: [
    OrganizationGroupType.gccLegalEntity,
    OrganizationGroupType.gccNaturalPerson,
  ].join(','),
  [FeOnlyOrganizationGroupType.ru]: OrganizationGroupType.ru,
};

const getQueryParams = (qs: string, allowedSortKeys: string[]) => {
  const { country, q, sort, type } = Object.fromEntries(
    new URLSearchParams(qs).entries()
  );

  return {
    sort: isSortValid(sort, allowedSortKeys) ? sort : '+name',
    q: q ? q.trim() : '',
    type:
      type &&
      feOnlyOrganizationGroupTypes.includes(type as FeOnlyOrganizationGroupType)
        ? type
        : '',
    country:
      country && supportedCountries.includes(country as SupportedCountry)
        ? country
        : '',
  };
};

export type QueryParams = ReturnType<typeof getQueryParams>;

interface Props {
  isCreateOrgDialogOpen: boolean;
  setIsCreateOrgDialogOpen: (isOpen: boolean) => void;
}

interface State {
  hasNextPage: boolean;
  isLoading: boolean;
  organizationGroups: PartialOrganizationGroup[];
}

const GroupsSubPage = (props: Props) => {
  const { url } = useRouteMatch();
  const { detailsParams } = useIsDetailsPageOpen('/:organizationGroupId', true);
  const history = useHistory();
  const dataGridRef = useGridApiRef();
  const location = useLocation();
  const api = useImperativeApi();
  const mounted = useMounted();
  const showPageError = useShowPageError();
  const setQueryParam = useSetQueryParam();
  const { columns, allowedSortKeys } = useColumns();
  const [state, setState] = useState<State>({
    hasNextPage: false,
    isLoading: true,
    organizationGroups: [],
  });
  const paramsRef = useRef(getQueryParams(location.search, allowedSortKeys));
  const pageRef = useRef(0);

  const selectedFiltersCount =
    +!!paramsRef.current.country + +!!paramsRef.current.type;

  const getData = async (
    page: number,
    limit = DEFAULT_PAGE_LIMIT,
    isLoadMore = false
  ) => {
    try {
      if (!isLoadMore) {
        setState((state) => ({
          ...state,
          isLoading: true,
        }));
      }
      const { country, q, sort, type: feType } = paramsRef.current;
      const apiType = feOnlyOrganizationGroupTypes.includes(
        feType as FeOnlyOrganizationGroupType
      )
        ? OrganizationGroupTypeApiFilterMapping[
            feType as FeOnlyOrganizationGroupType
          ]
        : undefined;

      const {
        organizationGroups,
        hasNextPage,
      } = await api.getOrganizationGroups({
        limit,
        page,
        sort,
        country: country || undefined,
        q: q.length ? q : undefined,
        type: apiType || undefined,
      });
      if (!mounted.current) return;
      setState((prevState) => ({
        ...prevState,
        isLoading: false,
        organizationGroups: isLoadMore
          ? [...prevState.organizationGroups, ...organizationGroups]
          : organizationGroups,
        hasNextPage,
      }));
    } catch (error) {
      showPageError(error);
      logError(error);
      if (!mounted.current) return;
      setState((prevState) => ({ ...prevState, isLoading: false }));
    }
  };

  useEffect(() => {
    if (dataGridRef.current && !state.isLoading) {
      dataGridRef.current.scroll({ left: 0, top: 0 });
    }

    paramsRef.current = getQueryParams(location.search, allowedSortKeys);
    pageRef.current = 0;
    getData(pageRef.current);
  }, [location.search]);

  const loadMoreItems = () => {
    pageRef.current++;
    getData(pageRef.current, undefined, true);
  };

  const handleSortModelChange = (sort: GridSortModel) => {
    if (state.isLoading || !state.organizationGroups.length) return;
    setQueryParam('sort', gridUtils.getNewSortParam(sort));
  };

  return (
    <>
      <Filters
        params={paramsRef.current}
        selectedFiltersCount={selectedFiltersCount}
        setParam={setQueryParam}
      />

      <LoaderWithOverlay loading={state.isLoading} />

      <DataGrid<PartialOrganizationGroup>
        apiRef={dataGridRef}
        getRowId={(row) => row.id}
        disableMultipleRowSelection
        keepNonExistentRowsSelected
        rowSelectionModel={
          detailsParams?.organizationGroupId
            ? [detailsParams.organizationGroupId]
            : []
        }
        initialState={{
          sorting: {
            sortModel: gridUtils.getSortModel(paramsRef.current.sort),
          },
        }}
        onSortModelChange={handleSortModelChange}
        loading={state.isLoading}
        rows={state.organizationGroups}
        columns={columns}
        onRowsScrollEnd={() => {
          if (!state.isLoading && state.hasNextPage) loadMoreItems();
        }}
        onRowClick={({ id, row }) => {
          if (dataGridRef.current?.getSelectedRows().has(id)) {
            history.push(`${url}${location.search}`);
          } else history.push(`${url}/${row.id}${location.search}`);
        }}
        slots={{
          noRowsOverlay: () => <NothingFound $top={0} $bottom={0} />,
          loadingOverlay: () => null,
        }}
      />

      <Route
        path={internalRootPaths.organizationGroupDetails}
        children={({ match }) => (
          <GroupDetailsPage
            open={!!match}
            refetchOrgGroups={() => getData(0)}
          />
        )}
      />

      <CreateGroupDialog
        open={props.isCreateOrgDialogOpen}
        onClose={() => props.setIsCreateOrgDialogOpen(false)}
        onSuccess={async () => await getData(0)}
      />
    </>
  );
};

export default GroupsSubPage;
