import { ReactNode, useEffect, useState } from 'react';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { Trans, useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';
import WidgetError from 'components/WidgetError';
import { useGlobalState } from 'context/GlobalState';
import { transformCardConfigs } from 'domains/card/utils';
import { LoaderWithOverlay } from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import { DEFAULT_PAGE_LIMIT, FeatureModuleByKeyMap } from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { useCanUser } from 'services/rbac';
import { getNetworkErrorCode, mapFeatureModules } from 'services/utils';
import { internalRootPaths } from './paths';

const OrganizationData = ({ children }: { children: ReactNode }) => {
  const { t } = useTranslation();
  const ldClient = useLDClient();
  const history = useHistory();
  const canUser = useCanUser();
  const api = useImperativeApi();
  const { enqueueSnackbar } = useSnackbar();
  const mounted = useMounted();
  const { orgId } = useParams<{ orgId: string }>();
  const { dispatch } = useGlobalState();
  const [error, setError] = useState<unknown>(null);
  const [isLoading, setIsLoading] = useState(true);

  const getAccountingData = async (
    featureModuleByKeyMap: FeatureModuleByKeyMap | null
  ) => {
    const accountingSettings = await api.getAccountingSettings(orgId);
    const [
      subcategories,
      vatRates,
      projects,
      { transactionCustomFields },
    ] = await Promise.all([
      accountingSettings.subcategoryEnabled
        ? api
            .getSubcategories(orgId)
            .then((data) => data.subcategories)
            .catch((error) => {
              logError(error);
              return null;
            })
        : null,
      accountingSettings.vatRateEnabled
        ? api
            .getVatRates(orgId)
            .then((data) => data.vatRates)
            .catch((error) => {
              logError(error);
              return null;
            })
        : null,
      accountingSettings.projectEnabled
        ? api
            .getProjects({ organizationId: orgId })
            .then((data) => data.projects)
            .catch((error) => {
              logError(error);
              return null;
            })
        : null,
      featureModuleByKeyMap &&
      featureModuleByKeyMap.TRANSACTION_CUSTOM_FIELDS.enabled &&
      canUser('custom-fields:view')
        ? api
            .getCustomFields({
              organizationId: orgId,
              page: 0,
              limit: DEFAULT_PAGE_LIMIT,
            })
            .catch((error) => {
              logError(error);
              return { transactionCustomFields: [] };
            })
        : { transactionCustomFields: [] },
    ]);
    return {
      accountingSettings,
      subcategories,
      vatRates,
      projects,
      transactionCustomFields,
    };
  };

  const getData = async () => {
    try {
      setError(null);
      setIsLoading(true);

      // Must come first to properly handle the "NOT_FOUND" error
      // (the id is taken from the URL, so it can be invalid)
      const organization = await api.getOrganization(orgId);

      const featureModuleByKeyMap = await api
        .getFeatureModules(orgId)
        .catch(() => {
          enqueueSnackbar(t('errors.featureModulesLoadingFailed'), {
            variant: 'error',
          });
          return null;
        });

      const [
        { cardAccounts },
        organizationIntegrations,
        { teams },
        {
          accountingSettings,
          subcategories,
          vatRates,
          projects,
          transactionCustomFields,
        },
        subscriptionPlan,
        { documents },
        unmatchedReceiptsCounts,
      ] = await Promise.all([
        api.getCardAccounts(orgId),
        api.getOrganizationIntegrations(orgId).catch((error) => {
          logError(error);
          return null;
        }),
        api.getTeams({ organizationId: orgId }),
        getAccountingData(featureModuleByKeyMap),
        api.getSubscriptionPlanParams(orgId).catch((error) => {
          logError(error);
          return null;
        }),
        api.getPublicDocuments(orgId).catch((error) => {
          logError(error);
          return { documents: null };
        }),
        canUser('receipt-inbox:view')
          ? api.getReceiptAutoMatchingCount(orgId).catch((error) => {
              logError(error);
              return { unmatchedTaskCount: 0, unmatchedTaskCountSelf: 0 };
            })
          : { unmatchedTaskCount: 0, unmatchedTaskCountSelf: 0 },
      ]);

      const flags = await ldClient?.identify({
        kind: 'multi',
        app: { key: 'internal' },
        user: {
          key: organization.id,
          name: organization.name,
          country: organization.billingAddress.country,
          partner: organization.partnerName,
          activatedAt: organization.activatedAt,
        },
      });

      const [
        { cardConfigs: cardConfigSettings },
        partnerOrgAuthDetails,
        partnerConfig,
      ] = await Promise.all([
        api
          .getCardConfigSettings(orgId)
          .then((data) =>
            transformCardConfigs(
              data,
              flags?.['request-card-for-another-member-enabled'] ?? false
            )
          )
          .catch((error) => {
            logError(error);
            return { cardConfigs: [] };
          }),
        canUser('partners:view')
          ? await api.getPartnerOrgAuthDetails(organization.partnerId, orgId)
          : undefined,
        canUser('partner-config:view')
          ? api.getPartnerConfig(organization.partnerId).catch((error) => {
              logError(error);
              return undefined;
            })
          : undefined,
      ]);

      if (!mounted.current) return;

      if (featureModuleByKeyMap) {
        dispatch({
          type: 'SET_FEATURE_MODULES',
          payload: mapFeatureModules(featureModuleByKeyMap),
        });
      }
      dispatch({
        type: 'SET_SUBSCRIPTION_PLAN_DATA',
        payload: { subscriptionPlan },
      });
      dispatch({ type: 'SET_DOCUMENTS', payload: { documents } });
      dispatch({
        type: 'SET_UNMATCHED_RECEIPTS_COUNT',
        payload: unmatchedReceiptsCounts,
      });
      dispatch({
        type: 'SET_CARD_CONFIG_SETTINGS',
        payload: { cardConfigSettings },
      });
      dispatch({
        type: 'SET_PARTNER_DATA',
        payload: {
          partnerOrgAuthDetails,
          partnerConfig,
        },
      });
      dispatch({
        type: 'SET_ORGANIZATION_DATA',
        payload: {
          organization,
          organizationIntegrations,
          teams,
          teamCount: teams.length,
          accountingSettings,
          subcategories,
          vatRates,
          projects,
          transactionCustomFields,
          cardAccounts,
          defaultCardAccount: cardAccounts.find(
            (item) => item.defaultAccount.value
          )!,
        },
      });
      setIsLoading(false);
    } catch (error) {
      if (!mounted.current) return;
      setError(error);
      setIsLoading(false);
      if (getNetworkErrorCode(error) !== 'NOT_FOUND') logError(error);
    }
  };

  const resetGlobalState = () => {
    dispatch({ type: 'RESET_FEATURE_MODULES' });
    dispatch({
      type: 'SET_SUBSCRIPTION_PLAN_DATA',
      payload: { subscriptionPlan: null },
    });
    dispatch({
      type: 'SET_ORGANIZATION_DATA',
      payload: {
        organization: null,
        organizationIntegrations: null,
        teams: [],
      },
    });
  };

  useEffect(() => {
    getData();
    return resetGlobalState;
  }, []);

  const renderContent = () => {
    if (isLoading) return null;

    if (!error) return children;

    if (getNetworkErrorCode(error) === 'NOT_FOUND')
      return (
        <WidgetError
          title={t('int.errors.orgNotFound.title')}
          description={
            <Trans
              i18nKey="int.errors.orgNotFound.description"
              values={{ id: orgId }}
            />
          }
          ctaText={t('int.errors.orgNotFound.cta')}
          onReload={() => history.push(internalRootPaths.organizations)}
        />
      );

    return <WidgetError onReload={getData} />;
  };

  return (
    <>
      {renderContent()}
      <LoaderWithOverlay loading={isLoading} />
    </>
  );
};

export default OrganizationData;
