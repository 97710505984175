import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import { orgOnboardingPaths } from 'components/App';
import { useGlobalState } from 'context/GlobalState';
import { DocumentLink } from 'domains/document/components';
import { ArchivedDocumentsDialog } from 'domains/document/dialogs';
import { getDocumentName, getDocumentUrl } from 'domains/document/utils';
import LanguageSelect from 'domains/member/components/LanguageSelect';
import { NotificationSettingsDialog } from 'domains/member/dialogs';
import { Box, Button, CaretLeftIcon, Typography } from 'elements';
import withPageConfig from 'hoc/withPageConfig';
import { ContentContainer, PageContent, PageHeader, PageTitle } from 'layout';
import { DocumentGenericType } from 'services/constants';
import { useFlags } from 'services/featureflags';
import { useCanUser } from 'services/rbac';
import InsuranceSection from './InsuranceSection';
import IssuanceLimitSection from './IssuanceLimitSection';
import PairedDevice from './PairedDevice';
import PasswordSection from './PasswordSection';
import PersonalDataSection from './PersonalDataSection';

const ProfilePage = ({ isOnboardingFlow }: { isOnboardingFlow?: boolean }) => {
  const { t } = useTranslation();
  const {
    state: { isComplianceRiskWhiteLabelApp, organization },
  } = useGlobalState();
  const {
    state: { documents, defaultCardAccount },
  } = useGlobalState();
  const { emailNotificationSettingsEnabled } = useFlags();
  const canUser = useCanUser();
  const [
    isArchivedDocumentsDialogOpen,
    setIsArchivedDocumentsDialogOpen,
  ] = useState(false);
  const [
    isNotificationSettingsDialogOpen,
    setIsNotificationSettingsDialogOpen,
  ] = useState(false);

  return (
    <>
      <PageHeader>
        <ContentContainer
          size={isOnboardingFlow ? 'sm' : 'md'}
          mx={isOnboardingFlow ? 'auto' : 0}
        >
          {isOnboardingFlow && (
            <Button
              size="large"
              variant="outlined"
              startIcon={<CaretLeftIcon />}
              component={RouterLink}
              to={generatePath(orgOnboardingPaths.orgOnboarding)}
              sx={{ mb: 2 }}
            >
              {t('profilePage.backToOnboarding')}
            </Button>
          )}

          <PageTitle title={t('profilePage.profileDetails')} />
        </ContentContainer>
      </PageHeader>

      <PageContent>
        <ContentContainer
          size={isOnboardingFlow ? 'sm' : 'md'}
          mx={isOnboardingFlow ? 'auto' : 0}
        >
          <PersonalDataSection />

          <PasswordSection />

          {!isComplianceRiskWhiteLabelApp && <PairedDevice />}

          <IssuanceLimitSection />

          {!isComplianceRiskWhiteLabelApp && emailNotificationSettingsEnabled && (
            <Box mb={8}>
              <Typography variant="h6" mb={1}>
                {t('profilePage.notificationSettings')}
              </Typography>
              <Button
                disabled={!canUser('profile-page-notification-settings:manage')}
                onClick={() => setIsNotificationSettingsDialogOpen(true)}
                size="large"
                variant="outlined"
              >
                {t('profilePage.notificationSettingsButton')}
              </Button>
            </Box>
          )}

          <Box mb={8}>
            <Typography variant="h6" mb={1}>
              {t('profilePage.language')}
            </Typography>
            <LanguageSelect />
          </Box>

          {!isComplianceRiskWhiteLabelApp && <InsuranceSection />}

          <Box mb={3}>
            <Typography variant="h6" mb={1}>
              {t('profilePage.legalDocuments')}
            </Typography>
            <div>
              {documents ? (
                [
                  DocumentGenericType.platformTermsAndConditions,
                  DocumentGenericType.platformDataPrivacyPolicyWeb,
                  DocumentGenericType.platformDataPrivacyPolicyMobile,
                  DocumentGenericType.bankTermsAndConditions,
                  DocumentGenericType.bankDataPrivacyPolicy,
                  DocumentGenericType.issuerTermsAndConditions,
                  DocumentGenericType.issuerDataPrivacyPolicy,
                  DocumentGenericType.insuranceTerms,
                ].map((genericType) => {
                  if (!documents[genericType]) return null;
                  const doc = documents[genericType][0];
                  return (
                    <DocumentLink
                      key={doc.documentId}
                      name={getDocumentName(
                        doc,
                        genericType,
                        organization!.partnerId,
                        defaultCardAccount!.accountGroup.value
                      )}
                      url={getDocumentUrl(doc.documentName)}
                    />
                  );
                })
              ) : (
                <Typography color="error">{t('errors.loadData')}</Typography>
              )}
            </div>
          </Box>
          <Button
            onClick={() => setIsArchivedDocumentsDialogOpen(true)}
            size="large"
            variant="outlined"
          >
            {t('profilePage.archivedDocuments')}
          </Button>
        </ContentContainer>
      </PageContent>

      <NotificationSettingsDialog
        open={isNotificationSettingsDialogOpen}
        onClose={() => setIsNotificationSettingsDialogOpen(false)}
      />

      <ArchivedDocumentsDialog
        open={isArchivedDocumentsDialogOpen}
        onClose={() => setIsArchivedDocumentsDialogOpen(false)}
      />
    </>
  );
};

export const CardholderProfilePage = withPageConfig(ProfilePage, {
  permission: 'ch-profile-page:visit',
});

export const AdminProfilePage = withPageConfig(ProfilePage, {
  permission: 'profile-page:visit',
});
