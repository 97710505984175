import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import CountryFlag from 'components/CountryFlag';
import FormatMoney from 'components/FormatMoney';
import {
  Chip,
  DataGridCell,
  GridColDef,
  gridUtils,
  Typography,
} from 'elements';
import {
  FeOnlyOrganizationGroupType,
  OrganizationGroupType,
  PartialOrganizationGroup,
} from 'services/constants';

const useColumns = () => {
  const { t, i18n } = useTranslation();

  return useMemo(() => {
    const columns: GridColDef<PartialOrganizationGroup>[] = [
      {
        headerName: t('int.orgsPage.name'),
        field: 'name',
        flex: 150,
        sortable: true,
      },
      {
        headerName: t('int.orgsPage.type'),
        field: 'type',
        flex: 100,
        sortable: false,
        renderCell: ({ row }) => (
          <DataGridCell>
            <Chip
              label={t(
                `int.organizationGroupType.${
                  OrganizationGroupType.ru === row.type
                    ? FeOnlyOrganizationGroupType.ru
                    : FeOnlyOrganizationGroupType.gcc
                }`
              )}
              size="small"
              sx={{ mr: 0.5 }}
            />
          </DataGridCell>
        ),
      },
      {
        headerName: t('int.orgsPage.country'),
        field: 'country',
        sortable: true,
        flex: 100,
        renderCell: ({ row }) => (
          <DataGridCell>
            <CountryFlag code={row.country} mr={1} />
            {row.country}
          </DataGridCell>
        ),
      },
      {
        headerName: t('int.orgsPage.linkedBusinesses'),
        field: 'organizationsCount',
        flex: 100,
        sortable: false,
      },
      {
        headerName: t('int.orgsPage.maxLimit'),
        field: 'maxLimit',
        flex: 150,
        sortable: false,
        renderCell: ({ row }) => (
          <DataGridCell>
            <Typography variant="inherit" noWrap>
              {row.maxLimit ? <FormatMoney value={row.maxLimit} /> : '-'}
            </Typography>
          </DataGridCell>
        ),
      },
    ];

    return {
      allowedSortKeys: gridUtils.getAllowedSortKeysDataGrid(columns),
      columns,
    };
  }, [i18n.language]);
};

export default useColumns;
