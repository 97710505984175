import { useMemo } from 'react';
import { AxiosRequestConfig } from 'axios';
import { env } from 'env';
import {
  AccountEntry,
  AccountEntryPageableList,
  AccountGroup,
  AccountingSettings,
  AccountingSystem,
  AccountingSystemList,
  AccountingTransactionList,
  ActivatedAndAvailableTpcConfigs,
  ActiveOptInRequest,
  AdditionalInfo,
  AddProjectPayload,
  Address,
  AddSupplierPayload,
  AgencyProfile,
  AgencyProfilePayload,
  ApproveLimitChangeRequestPayload,
  AssignSupplierPayload,
  Bank,
  BankAccount,
  BankAccountList,
  BankAccountNumber,
  BankAccountType,
  BankConnectionImportStatus,
  BankConnectionList,
  BankForConnectionPageableList,
  BankSubmission,
  BatchPaymentList,
  BillList,
  BillPaymentBatch,
  BillPaymentBatchPageableList,
  BillPaymentsBankAccount,
  BillPaymentsBankAccountPageableList,
  BillPaymentType,
  BulkUploadError,
  CarbonPrice,
  Card,
  CardAccount,
  CardAccountBalance,
  CardAccountCurrency,
  CardAccountList,
  CardAccountPaymentSettingsPayload,
  CardAccountSettings,
  CardAccountsSettingsPageableList,
  CardConfigSettingList,
  CardControlRegion,
  CardCount,
  CardCountAndLimitByConfigMap,
  CardDesignList,
  CardHistoryEntryPageableList,
  CardPageableList,
  CardReplacementReason,
  CardRequest,
  CardsCardTxCustomFieldsResponseList,
  CardTerminationReason,
  CardVelocityGroupLimits,
  Cashback,
  CashbackRedemptionBankAccountList,
  CashbackRedemptionBatch,
  CashbackRedemptionBatchPageableList,
  CashbackRedemptionList,
  CashbackType,
  ChangeCardLimitsPayload,
  CodatAccountItem,
  CodatCompanyBankAccountsPageableList,
  CodatCompanyConfig,
  CodatCompanyCustomersPageableList,
  CodatCompanySuppliersPageableList,
  CodatData,
  CodatDataRefreshed,
  CodatExpenseSettings,
  CodatMappingOptionsSummary,
  CodatMappingTarget,
  CodatSupplierItem,
  CodatTaxRateItem,
  CodatTrackingCategoryItem,
  CompanyRegistryProvider,
  CompensationBatchDetails,
  CompensationBatchPageableList,
  CompensationCertificationProjectList,
  CompensationStatementList,
  ComplianceCheckCompleted,
  ComplianceCompany,
  ComplianceRating,
  ComplianceRatingPayload,
  ComplianceStaticData,
  CreateCardRequestPayload,
  CreateLimitChangeRequestPayload,
  CreateOnboardingDocument,
  CreateOrganizationPayload,
  CreateOrgManuallyPayload,
  CreatePayoutPaymentPayload,
  CreateTeamPayload,
  CreditAssessment,
  CreditAssessmentLimits,
  CreditAssessmentLimitsPayload,
  CreditAssessmentSummary,
  CreditAssessmentSummaryPayload,
  CreditAssessmentUnderwritingCase,
  CreditAssessmentUnderwritingCasePayload,
  CreditProfile,
  CreditStaticData,
  CrefoReportList,
  CrmInconsistency,
  CurrencyCashbackByCurrencyMap,
  CustomerSummary,
  CustomerSummaryPayload,
  CustomField,
  CustomFieldOption,
  CustomFieldOptionsPageableList,
  CustomFieldsPageableList,
  DatevClientList,
  DatevConnection,
  DatevExportTransactions,
  Device,
  DirectDebitInfo,
  DocumentsByGenericTypeMap,
  DocumentType,
  DownloadExportFlowFilePayload,
  ExistingActiveOptInRequest,
  ExportFlowSummary,
  ExportFlowTransactionList,
  ExternalReport,
  ExternalReportProvider,
  FeatureModuleByKeyMap,
  FeatureModuleKey,
  Firmographics,
  FirmographicsFundingPayload,
  FirmographicsSegmentationPayload,
  FirmographicsSizePayload,
  ForwardingIntegrationFrequency,
  ForwardingIntegrations,
  ForwardingIntegrationStatus,
  FxFeeAndVolume,
  FxRateList,
  FxRateListDEPRECATED,
  GeneralInfo,
  GetAccountEntriesCsvParams,
  GetAccountEntriesParams,
  GetBillingStmtParams,
  GetBillPaymentBatchesParams,
  GetBillPaymentsBankAccountsParams,
  GetBillPaymentsCsvParams,
  GetBillPaymentsXmlParams,
  GetCardAccountsSettingsParams,
  GetCardCountsAndLimitsParams,
  GetCardHistoryParams,
  GetCardholderProjectsParams,
  GetCardsParams,
  GetCodatCompanyBankAccountsParams,
  GetCodatCompanyCustomersParams,
  GetCodatCompanySuppliersParams,
  GetCompensationBatchesParams,
  GetCustomFieldOptionsPayload,
  GetCustomFieldsPayload,
  GetExportFlowFileParams,
  GetFxFeeAndVolumeParams,
  GetFxRatesParams,
  GetGlobalTransactionsParams,
  GetHistoryCashbackRedemptionsParams,
  GetLocoiaEmbedSettingsParams,
  GetMembersParams,
  GetMerchantsParams,
  GetMissingReceiptCountParams,
  GetOrganizationGroupsParams,
  GetOrganizationMerchantsParams,
  GetPartialMembersParams,
  GetProjectsParams,
  GetReceiptsAutoMatchingParams,
  GetReceivableRepaymentsParams,
  GetReviewStatusCountParams,
  GetSuppliersParams,
  GetTeamsParams,
  GetTransactionsParams,
  HistoryCashbackRedemptionPageableList,
  HubspotCompany,
  IdentificationType,
  InternalIndustryType,
  InviteMemberPayload,
  IssueCardPayload,
  LatestXslxUploadResponse,
  LimitChangeRequest,
  LocoiaEmbedSettingsPageableList,
  LocoiaGeneratedJwt,
  ManageCustomFieldOptionPayload,
  ManageCustomFieldPayload,
  MappedCodatDropdownOptionsPayload,
  MappedCodatProjectsPayload,
  MappedCodatSubcategoriesPayload,
  MappedCodatSuppliersPayload,
  MappedCodatTaxRatesPayload,
  MappedCodatTeamsPayload,
  MasterData,
  MasterDataPayload,
  MccCategory,
  MemberCount,
  MemberDetails,
  MemberPageableList,
  MemberPermissions,
  MembershipList,
  MerchantDescriptionSource,
  MerchantDetails,
  MerchantPageableList,
  MerchantStatus,
  MerchantVolumeItemList,
  MinimalTeam,
  Money,
  MonthlyCashbackProgressList,
  MonthlyOrgEmissionsByYear,
  NaceIndustryCode,
  NotificationSetting,
  NotificationSettingsCategorized,
  Onboarding,
  OnboardingDocument,
  OnboardingDocumentFile,
  OnboardingDocumentType,
  Organization,
  OrganizationAccountStatement,
  OrganizationAccountStatementPayment,
  OrganizationAccountStatementsResponse,
  OrganizationAccountType,
  OrganizationBalance,
  OrganizationBillPaymentsPageableResponse,
  OrganizationEmission,
  OrganizationGroup,
  OrganizationGroupCreatePayload,
  OrganizationGroupMember,
  OrganizationIntegrations,
  OrganizationMerchant,
  OrganizationMerchantPageableList,
  OrganizationPartner,
  OrganizationProductType,
  OrganizationRepresentative,
  OrganizationRepresentativeAdditionalInfo,
  OrganizationRepresentativeAdditionalInfoUpdatePayload,
  OrganizationRepresentativePayload,
  OrganizationSearchResult,
  OrganizationsStaticData,
  OrganizationStatus,
  PartialMemberPageableList,
  PartialOrganizationGroupPageableList,
  PartialOrganizationPageableList,
  PartnerAudit,
  PartnerAuthStatus,
  PartnerConfig,
  PartnerDeal,
  PartnerIdType,
  PartnerOrgAuthDetails,
  PartnerPageableList,
  Payment,
  PaymentDeferment,
  PaymentFrequency,
  PaymentFrequencyType,
  Pin,
  PlatformLegalForm,
  PremiumCardFeeTier,
  PremiumCardFeeTierList,
  PremiumCardInvoice,
  ProcessingAccount,
  ProgramSettlement,
  ProgramSettlementPageableList,
  ProgramSettlementPaymentDetails,
  ProgramSettlementPaymentsFile,
  Project,
  ProjectPageableList,
  ProjectUploadList,
  Receipt,
  ReceiptAutoMatchingDetails,
  ReceiptList,
  ReceiptRejectionReason,
  ReceiptsAutoMatchingAttachment,
  ReceiptsAutoMatchingPageableList,
  ReceiptsAutoMatchingSameEmail,
  ReceiptsAutoMatchingThumbnailsList,
  ReceiptsSettings,
  ReceivableRepayment,
  ReceivableRepaymentAmountPageableList,
  ReceivableRepaymentsPageableList,
  RelatedIndividual,
  RelatedIndividualDetails,
  RelatedIndividualType,
  RelatedPerson,
  RequestOnboardingDocument,
  ReviewStatusCount,
  RiskAndCompliance,
  RiskAssessmentCompleted,
  Settlement,
  SettlementGroupList,
  SettlementPageableList,
  SettlementPaymentList,
  SettlementTransactionPageableList,
  SmsLog,
  SpanishProvince,
  SplitMerchantDetails,
  Subcategory,
  SubcategoryPageableList,
  SubcategoryUploadList,
  SubscriptionPlan,
  SubscriptionPlanType,
  Supplier,
  SupplierDetails,
  SupplierList,
  SupplierPageableList,
  SupportedCountry,
  SupportedWebAppLanguage,
  SyncSettings,
  TacMetadataList,
  Team,
  TeamPageableList,
  TeamTransactionAssignmentList,
  ThreeDsAuthRequest,
  Timezone,
  ToggleFeatureModulePayload,
  TopCardSpenderList,
  Transaction,
  TransactionEmissionList,
  TransactionPageableList,
  UnAssignSupplierPayload,
  UpdateAccountingGeneralAccountsPayload,
  UpdateAccountingGeneralSettingsPayload,
  UpdateAccountingSettingsPayload,
  UpdateAccountingTransactionsPayload,
  UpdateAccTxnCustomFieldPayload,
  UpdateAccTxnCustomFieldResponse,
  UpdateBankAccountStatusData,
  UpdateCardAccountSettingsPayload,
  UpdateCardControlsRequestPayload,
  UpdateCardPayload,
  UpdateCardsCardTxCustomFieldsPayload,
  UpdateCirculaSettingsPayload,
  UpdateCodatAccountsPayload,
  UpdateCodatCompanyConfigData,
  UpdateCodatExpenseSettingsPayload,
  UpdateCodatSuppliersPayload,
  UpdateCodatTaxRatesPayload,
  UpdateCodatTrackingCategoriesPayload,
  UpdateComplianceDocumentsPayload,
  UpdateDatevAccountingSettingsPayload,
  UpdateFeatureModuleSettingsPayload,
  UpdateMandatoryExportSettingsPayload,
  UpdateMemberTeamsPayload,
  UpdateMerchantPayload,
  UpdateProjectPayload,
  UpdateReceiptPayload,
  UpdateReceiptSettingsPayload,
  UpdateSupplierPayload,
  UpdateTeamMembersPayload,
  UpdateTeamPayload,
  UpdateTeamPermissionsPayload,
  UpdateTransactionsExportPayload,
  UpdateTransactionsReviewPayload,
  UploadGlAccountParams,
  UploadProjectParams,
  UploadSupplierParams,
  VatRate,
  VatRatePageableList,
  XlsxUploadResponse,
} from 'services/constants';
import { transformArrayBufferToString } from 'services/utils';
import useRequest from './useRequest';

// todo: should we split this into separate files?
const useImperativeApi = () => {
  const request = useRequest();

  return useMemo(
    () => ({
      getFeatureModules(organizationId: string) {
        return request<FeatureModuleByKeyMap>(`/features/${organizationId}`);
      },
      toggleFeatureModule(
        organizationId: string,
        data: ToggleFeatureModulePayload
      ) {
        return request<FeatureModuleByKeyMap>(`/features/${organizationId}`, {
          method: 'put',
          data,
        });
      },
      updateFeatureModuleSettings<FMK extends FeatureModuleKey>(
        organizationId: string,
        data: UpdateFeatureModuleSettingsPayload<FMK>
      ) {
        return request<FeatureModuleByKeyMap[FMK]>(
          `/features/${organizationId}/settings`,
          {
            method: 'put',
            data,
          }
        );
      },
      toggleInternalFeatureModule(
        organizationId: string,
        data: ToggleFeatureModulePayload
      ) {
        return request<FeatureModuleByKeyMap>(
          `/features/internal/${organizationId}`,
          {
            method: 'put',
            data,
          }
        );
      },
      getGeneralInfo(organizationId: string) {
        return request<GeneralInfo>('/general-info', {
          params: { organizationId },
        });
      },
      getAccountGroups({
        country,
        currency,
        partnerId,
      }: {
        country: SupportedCountry;
        currency: CardAccountCurrency;
        partnerId: PartnerIdType;
      }) {
        return request<{ accountGroups: AccountGroup[] }>(
          '/organizations/account-groups',
          {
            params: { country, currency, partnerId },
          }
        );
      },
      getPlatformLegalForms() {
        return request<{ legalForms: PlatformLegalForm[] }>(
          '/legal-forms/platform-legal-forms'
        );
      },
      getOrganizationSearch(
        name: string,
        country: SupportedCountry,
        provider: CompanyRegistryProvider
      ) {
        return request<OrganizationSearchResult>('data/company-search', {
          params: {
            name,
            country,
            provider,
          },
        });
      },
      checkHubspotId(hubspotId: string) {
        return request<HubspotCompany>(`/organizations/hubspot/${hubspotId}`);
      },
      updateHubspotId(organizationId: string, hubspotId: string | null) {
        return request<{
          hubspotId: string;
          organizationId: string;
        }>(`/organizations/${organizationId}/hubspot-id`, {
          method: 'put',
          data: {
            hubspotId,
          },
        });
      },
      createOrganization(data: CreateOrganizationPayload) {
        return request<Organization>('/organizations', {
          method: 'post',
          data,
        });
      },
      createOrganizationManually(data: CreateOrgManuallyPayload) {
        return request<Organization>('/organizations/manual', {
          method: 'post',
          data,
        });
      },
      createNonCustomerOrganization(data: CreateOrganizationPayload) {
        return request<Organization>('/organizations/non-customer', {
          method: 'post',
          data,
        });
      },
      createNonCustomerOrganizationManually(data: CreateOrgManuallyPayload) {
        return request<Organization>('/organizations/manual-non-customer', {
          method: 'post',
          data,
        });
      },
      getCrmInconsistencies(limit = 20000) {
        return request<{
          inconsistencies: CrmInconsistency[];
          hasNextPage: boolean;
        }>('/crm/inconsistencies', {
          params: { limit },
        });
      },
      getOrganizations(params: object) {
        return request<PartialOrganizationPageableList>('/organizations', {
          params,
        });
      },
      getOrganization(id: string) {
        return request<Organization>(`/organizations/${id}`);
      },
      getOrganizationGroups(params: GetOrganizationGroupsParams) {
        return request<PartialOrganizationGroupPageableList>(
          '/organization-groups',
          {
            params,
          }
        );
      },
      createOrganizationGroup(data: OrganizationGroupCreatePayload) {
        return request<OrganizationGroup>('/organization-groups', {
          method: 'post',
          data,
        });
      },
      deleteOrganizationGroup(organizationGroupId: string) {
        return request<OrganizationGroup>(
          `/organization-groups/${organizationGroupId}`,
          {
            method: 'delete',
          }
        );
      },
      getOrganizationGroup(organizationGroupId: string) {
        return request<OrganizationGroup>(
          `/organization-groups/${organizationGroupId}`
        );
      },
      getOrganizationGroupMembers(organizationGroupId: string) {
        return request<{
          organizations: OrganizationGroupMember[];
          totalLimit: Money;
        }>(`/organization-groups/${organizationGroupId}/organizations`);
      },
      getCardAccounts(orgId: string) {
        return request<CardAccountList>(
          `/organizations/${orgId}/card-accounts`
        );
      },
      createCardAccount(
        orgId: string,
        accountName: string,
        currency?: CardAccountCurrency
      ) {
        return request<CardAccount>(`/organizations/${orgId}/card-accounts`, {
          method: 'post',
          data: { accountName, currency },
        });
      },
      renameCardAccount(orgId: string, cardAccountId: string, newName: string) {
        return request<CardAccount>(
          `/organizations/${orgId}/card-accounts/${cardAccountId}`,
          {
            method: 'put',
            data: { name: newName },
          }
        );
      },
      getCardAccountBalance(cardAccountId: string) {
        return request<CardAccountBalance>(
          '/tx-export/balance/card-account-balance',
          { params: { cardAccountId } }
        );
      },
      getOrganizationIntegrations(id: string) {
        return request<OrganizationIntegrations>(
          `/integrations/settings/organization/${id}`
        );
      },
      getOrganizationBalance(id: string, cardAccountId?: string) {
        return request<OrganizationBalance>(`/balance/organization/${id}`, {
          params: { cardAccountId },
        });
      },
      getOrganizationTopCardSpenders(organizationId: string) {
        return request<TopCardSpenderList>(
          '/tx-export/balance/top-card-spenders',
          { params: { organizationId } }
        );
      },
      getOrganizationOnboardingDetails(id: string) {
        return request<Onboarding>(`/organizations/${id}/onboarding`);
      },
      deactivateOrganization(id: string) {
        return request<Organization>(`/organizations/${id}/deactivate`, {
          method: 'put',
        });
      },
      updateMainCardAccountAccountGroup(
        id: string,
        accountGroup: AccountGroup,
        currency: CardAccountCurrency
      ) {
        return request<Organization>(
          `/organizations/${id}/default-card-account-group`,
          {
            method: 'put',
            data: { accountGroup, currency },
          }
        );
      },
      toggleOrgAutomaticActivation(id: string, enable: boolean) {
        return request<Organization>(
          `/organizations/${id}/automatic-activation`,
          {
            method: 'post',
            params: { enable },
          }
        );
      },
      confirmOrganizationDetails(id: string) {
        return request<Onboarding>(`/organizations/${id}/org-details`, {
          method: 'post',
        });
      },
      cancelOrganization(id: string) {
        return request<void>(`/organizations/${id}/cancel`, {
          method: 'post',
        });
      },
      resumeOnboarding(id: string) {
        return request<Organization>(`/organizations/${id}/onboarding/resume`, {
          method: 'post',
        });
      },
      switchOrganization(organizationId: string) {
        return request(`/organizations/${organizationId}/switch-to`, {
          method: 'post',
        });
      },
      prepareSddMandate(bankAccountId: string) {
        return request<DirectDebitInfo>('/direct-debit-mandates', {
          method: 'post',
          params: { bankAccountId },
        });
      },
      confirmSddMandate(id: string) {
        return request<DirectDebitInfo>(`/direct-debit-mandates/${id}`, {
          method: 'post',
        });
      },
      changeMandateTypeToB2b(orgId: string, mandateReference: string) {
        return request<DirectDebitInfo>(
          `/direct-debit-mandates/${orgId}/change-type`,
          {
            method: 'post',
            params: {
              mandateType: 'B2B',
              mandateReference,
            },
          }
        );
      },
      uploadMandateFile(mandateReference: string, file: File) {
        const data = new FormData();
        data.append('file', file);
        return request<Document>(
          `/documents/signed-direct-debit-mandate-by-identifier`,
          {
            method: 'post',
            headers: { 'Content-Type': 'multipart/form-data' },
            params: { identifier: mandateReference },
            data,
          }
        );
      },
      confirmSddMandateInOnboarding(
        organizationId: string,
        bankAccountId: string,
        id: string
      ) {
        return request<Onboarding>(
          `/organizations/${organizationId}/bank-accounts/${bankAccountId}/direct-debit-mandates/${id}`,
          {
            method: 'post',
          }
        );
      },
      getSddMandate(id: string) {
        return request<DirectDebitInfo>(
          `/organizations/${id}/direct-debit-info`
        );
      },
      resetSddMandate(organizationId: string) {
        return request<Onboarding>(
          `organizations/${organizationId}/onboarding/reset-sdd-mandate`,
          {
            method: 'post',
          }
        );
      },
      getTermsAndConditions(organizationId: string) {
        return request<TacMetadataList>(
          `/organizations/${organizationId}/terms-and-conditions`
        );
      },
      confirmTermsAndConditions(organizationId: string) {
        return request<Onboarding>(
          `/organizations/${organizationId}/terms-and-conditions`,
          {
            method: 'post',
          }
        );
      },
      resetTermsAndConditions(organizationId: string) {
        return request<Onboarding>(
          `organizations/${organizationId}/onboarding/reset-terms-and-conditions`,
          {
            method: 'post',
          }
        );
      },
      assignCardProcessingAccountBalance(
        id: string,
        cardAccountId: string,
        accountNumber: string
      ) {
        return request<{ iban: string }>(
          `/organizations/${id}/card-processing-account`,
          { method: 'put', data: { accountNumber, cardAccountId } }
        );
      },
      updateBillingEmailAddress(organizationId: string, data: object) {
        return request<Organization>(
          `/organizations/${organizationId}/billing-email`,
          {
            method: 'put',
            data,
          }
        );
      },
      updateDeliveryAddress(organizationId: string, data: Partial<Address>) {
        return request<Organization>(
          `/organizations/${organizationId}/delivery-address`,
          {
            method: 'put',
            data,
          }
        );
      },
      getBank(iban: string) {
        return request<Bank>(`/banks`, { params: { iban } });
      },
      getBankConnections(organizationId: string) {
        return request<BankConnectionList>('/bank-connections', {
          params: { organizationId },
        });
      },
      deleteBankConnection(id: number, type: BankAccountType) {
        return request(`/bank-connections/${id}`, {
          method: 'delete',
          params: { type },
        });
      },
      authorizeBankConnection(id: number, accountIds: string[]) {
        return request(`/bank-connections/${id}/authorization`, {
          method: 'post',
          data: { accountIds },
        });
      },
      getBankConnectionImportStatus(organizationId: string) {
        return request<BankConnectionImportStatus>(
          '/bank-connections/import-status',
          { params: { organizationId } }
        );
      },
      deleteBankConnectionImportStatus(organizationId: string) {
        return request<BankConnectionImportStatus>(
          '/bank-connections/import-status',
          { method: 'delete', params: { organizationId } }
        );
      },
      getUnauthorizedBankAccounts(bankConnectionId: number) {
        return request<BankAccountList>('/bank-accounts/unauthorized', {
          params: { bankConnectionId },
        });
      },
      getBankAccounts(organizationId: string) {
        return request<BankAccountList>('/bank-accounts', {
          params: { organizationId },
        });
      },
      updateBankAccountStatus(data: UpdateBankAccountStatusData) {
        return request<BankAccount>('/bank-accounts/transfers-allowed-status', {
          method: 'post',
          data,
        });
      },
      addBankAccount(accountNumber: BankAccountNumber) {
        return request(`/bank-accounts`, {
          method: 'post',
          data: { accountNumber },
        });
      },
      addBankAccountInOnboarding(
        organizationId: string,
        accountNumber: BankAccountNumber
      ) {
        return request<BankAccount>(
          `/organizations/${organizationId}/bank-accounts`,
          {
            method: 'post',
            data: { accountNumber },
          }
        );
      },
      confirmBankAccountsInOnboarding(organizationId: string) {
        return request<Onboarding>(
          `/organizations/${organizationId}/bank-accounts-confirmation`,
          { method: 'post' }
        );
      },
      deleteBankAccount(organizationId: string, bankAccountId: string) {
        return request(`/bank-accounts/${bankAccountId}`, { method: 'delete' });
      },
      updateLegalName(organizationId: string, legalName: string) {
        return request<Organization>(
          `/organizations/${organizationId}/legal-name`,
          { method: 'put', data: { legalName } }
        );
      },
      updateBillingAddress(organizationId: string, data: object) {
        return request<Organization>(
          `/organizations/${organizationId}/billing-address`,
          {
            method: 'put',
            data,
          }
        );
      },
      updateRegistration(organizationId: string, data: object) {
        return request<Organization>(
          `/organizations/${organizationId}/registration`,
          {
            method: 'put',
            data,
          }
        );
      },
      updateFoundationDate(organizationId: string, data: object) {
        return request<Organization>(
          `/organizations/${organizationId}/foundation`,
          {
            method: 'put',
            data,
          }
        );
      },
      updateAccountType(
        organizationId: string,
        accountType: OrganizationAccountType
      ) {
        return request<Organization>(
          `/organizations/${organizationId}/account-type`,
          { method: 'put', data: { accountType } }
        );
      },
      updateProductType(
        organizationId: string,
        productType: OrganizationProductType
      ) {
        return request<Organization>(
          `/organizations/${organizationId}/product-type?newProductType=${productType}`,
          { method: 'put' }
        );
      },
      updateBillPaymentTransferType(
        organizationId: string,
        billPaymentTransferType: BillPaymentType
      ) {
        return request<Organization>(
          `/organizations/${organizationId}/bill-payment-transfer-type?newTransferType=${billPaymentTransferType}`,
          { method: 'put' }
        );
      },
      getBankSubmissions(organizationId: string) {
        return request<BankSubmission[]>(
          `/organizations/${organizationId}/bank-submissions`
        );
      },
      getBankSubmissionRawData(
        organizationId: string,
        bankSubmissionId: string
      ) {
        return request<{ rawData: string; version: number }>(
          `/organizations/${organizationId}/bank-submissions/${bankSubmissionId}/raw-data`
        );
      },
      createBankSubmission(organizationId: string) {
        return request<BankSubmission>(
          `/organizations/${organizationId}/bank-submissions`,
          { method: 'post' }
        );
      },
      getRelatedIndividuals(organizationId: string) {
        return request<{ relatedIndividuals: RelatedIndividual[] }>(
          `/organizations/${organizationId}/external-related-individuals`
        );
      },
      getRelatedIndividual(
        relatedIndividualId: string,
        type: RelatedIndividualType
      ) {
        return request<RelatedIndividualDetails>(
          `/organizations/external-related-individuals/${relatedIndividualId}?relatedIndividualIdType=${type}`
        );
      },
      getOrganizationRepresentatives(organizationId: string) {
        return request<OrganizationRepresentative[]>(
          `/organizations/${organizationId}/representatives`
        );
      },
      getRelatedPersons(organizationId: string) {
        return request<{ relatedPersons: RelatedPerson[] }>(
          `/organizations/${organizationId}/related-persons`
        );
      },
      createOrganizationRelatedPerson(
        organizationId: string,
        relatedPerson: OrganizationRepresentativePayload
      ) {
        return request<RelatedPerson>(
          `/organizations/${organizationId}/related-persons`,
          {
            method: 'post',
            data: relatedPerson,
          }
        );
      },
      updateOrganizationRelatedPerson(
        organizationId: string,
        relatedPersonId: string,
        relatedPerson: OrganizationRepresentativePayload
      ) {
        return request<RelatedPerson>(
          `/organizations/${organizationId}/related-persons/${relatedPersonId}`,
          {
            method: 'put',
            data: relatedPerson,
          }
        );
      },
      archiveOrganizationRelatedPersons(
        organizationId: string,
        relatedPersonId: string
      ) {
        return request<RelatedPerson>(
          `/organizations/${organizationId}/related-persons/${relatedPersonId}`,
          {
            method: 'delete',
          }
        );
      },
      markOrganizationRelatedPersonReadyForSubmission(
        organizationId: string,
        relatedPersonId: string
      ) {
        return request<RelatedPerson>(
          `/organizations/${organizationId}/related-persons/${relatedPersonId}/ready-for-submission`,
          {
            method: 'post',
          }
        );
      },
      uploadOrganizationRepresentativeIdNowXml(
        orgId: string,
        representativeId: string,
        data: string
      ) {
        return request<OrganizationRepresentative>(
          `/organizations/${orgId}/representatives/${representativeId}/update_with_idnow_data`,
          {
            method: 'put',
            headers: { 'Content-Type': 'application/xml' },
            data,
          }
        );
      },
      requestOrganizationRelatedPersonAdditionalInfo(
        orgId: string,
        RelatedPersonId: string,
        reasonForAdditionalInfoRequest: string
      ) {
        return request<RelatedPerson>(
          `/organizations/${orgId}/related-persons/${RelatedPersonId}/request-additional-info`,
          {
            method: 'post',
            data: { reasonForAdditionalInfoRequest },
          }
        );
      },
      uploadOrganizationRepresentativeWebIdJson(
        orgId: string,
        representativeId: string,
        data: string
      ) {
        return request<OrganizationRepresentative>(
          `/organizations/${orgId}/representatives/${representativeId}/update_with_webid_data`,
          {
            method: 'put',
            headers: { 'Content-Type': 'application/json' },
            data,
          }
        );
      },
      createRepresentativLegitimationRequest(
        representativeId: string,
        identificationType: IdentificationType
      ) {
        return request<void>(
          `/organizations/representatives-additional-info/${representativeId}/legitimation`,
          {
            method: 'post',
            params: { identificationType },
          }
        );
      },
      deleteRepresentativLegitimationRequest(representativeId: string) {
        return request<void>(
          `/organizations/representatives-additional-info/${representativeId}/legitimation`,
          {
            method: 'delete',
          }
        );
      },
      getOrganizationRepresentativesAdditionalInfo(organizationId: string) {
        return request<OrganizationRepresentativeAdditionalInfo[]>(
          `/organizations/${organizationId}/representatives-additional-info`
        );
      },
      updateOrganizationRepresentativesAdditionalInfo(
        orgId: string,
        representativeId: string,
        data: OrganizationRepresentativeAdditionalInfoUpdatePayload
      ) {
        return request<OrganizationRepresentativeAdditionalInfo>(
          `/organizations/${orgId}/representatives-additional-info/${representativeId}`,
          {
            method: 'put',
            data,
          }
        );
      },
      cancelOrganizationRelatedPersonAdditionalInfo(
        orgId: string,
        relatedPersonId: string
      ) {
        return request<OrganizationRepresentativeAdditionalInfo>(
          `/organizations/${orgId}/related-persons/${relatedPersonId}/additional-info`,
          {
            method: 'delete',
          }
        );
      },
      confirmOrganizationRepresentativesAdditionalInfo(orgId: string) {
        return request<OrganizationRepresentativeAdditionalInfo[]>(
          `/organizations/${orgId}/representatives-additional-info`,
          {
            method: 'post',
          }
        );
      },
      getOnboardingDocumentTypes() {
        return request<{ onboardingDocumentTypes: OnboardingDocumentType[] }>(
          `/onboarding-document-types`
        );
      },
      createOnboardingDocuments(
        orgId: string,
        onboardingDocumentsToCreate: CreateOnboardingDocument[]
      ) {
        return request<{ onboardingDocuments: OnboardingDocument[] }>(
          `/onboarding-documents/${orgId}/documents`,
          {
            method: 'post',
            data: {
              onboardingDocumentsToCreate,
            },
          }
        );
      },
      updateOnboardingDocument(
        orgId: string,
        onboardingDocumentId: string,
        data: {
          customLabel: string;
          customLabelLocal: string;
          attributes: { [key: string]: string } | null;
          description: string;
          descriptionLocal: string;
          answer?: string;
          answerLocal?: string;
        }
      ) {
        return request<OnboardingDocument>(
          `onboarding-documents/${orgId}/documents/${onboardingDocumentId}`,
          {
            method: 'put',
            data,
          }
        );
      },
      getOnboardingDocuments(orgId: string, relatedEntityId?: string) {
        return request<{ onboardingDocuments: OnboardingDocument[] }>(
          `/onboarding-documents/${orgId}/documents`,
          {
            params: {
              ...(relatedEntityId && { relatedEntityId }),
            },
          }
        );
      },
      uploadOnboardingDocumentFile(
        orgId: string,
        docId: string,
        file: File,
        onUploadProgress: (percent: number) => void
      ) {
        const data = new FormData();
        data.append('file', file);
        return request<OnboardingDocumentFile>(
          `/onboarding-documents/${orgId}/documents/${docId}/files`,
          {
            method: 'post',
            headers: { 'Content-Type': 'multipart/form-data' },
            data,
            onUploadProgress(progressEvent) {
              const { loaded, total } = progressEvent;
              if (total) {
                const percent = Math.round((loaded * 100) / total);
                onUploadProgress(percent);
              }
            },
          }
        );
      },
      deleteOnboardingDocumentFile(
        orgId: string,
        docId: string,
        fileId: string
      ) {
        return request<void>(
          `/onboarding-documents/${orgId}/documents/${docId}/files/${fileId}`,
          {
            method: 'delete',
          }
        );
      },
      downloadOnboardingDocumentFile(
        orgId: string,
        docId: string,
        fileId: string,
        transformResponse: boolean = false
      ) {
        return request<string>(
          `/onboarding-documents/${orgId}/documents/${docId}/files/${fileId}`,
          {
            responseType: 'arraybuffer',
            fullResponse: true,
            ...(transformResponse && {
              transformResponse: transformArrayBufferToString,
            }),
          }
        );
      },
      downloadLegalRepIdentificationArchive(orgId: string, repId: string) {
        return request<{ data: string }>(
          `/organizations/${orgId}/representatives/${repId}/identification-archive`
        );
      },
      submitOnboardingAnswer(
        orgId: string,
        onboardingDocumentId: string,
        data: { answerLocal: string }
      ) {
        return request<{ onboardingDocuments: OnboardingDocument[] }>(
          `/onboarding-documents/${orgId}/documents/${onboardingDocumentId}/submit-answer`,
          {
            method: 'put',
            data,
          }
        );
      },
      submitOnboardingDocuments(
        orgId: string,
        onboardingDocumentIds: string[]
      ) {
        return request<{ onboardingDocuments: OnboardingDocument[] }>(
          `/onboarding-documents/${orgId}/submit-documents`,
          {
            method: 'post',
            data: {
              onboardingDocumentIds,
            },
          }
        );
      },
      requestOnboardingDocuments(
        orgId: string,
        onboardingDocumentsToRequest: RequestOnboardingDocument[]
      ) {
        return request<{ onboardingDocuments: OnboardingDocument[] }>(
          `/onboarding-documents/${orgId}/request-documents`,
          {
            method: 'post',
            data: {
              onboardingDocumentsToRequest,
            },
          }
        );
      },
      archiveOnboardingDocument(orgId: string, onboardingDocumentId: string) {
        return request<void>(
          `/onboarding-documents/${orgId}/documents/${onboardingDocumentId}`,
          { method: 'delete' }
        );
      },
      createBankSubmissionRequest(
        organizationId: string,
        bankSubmissionId: string
      ) {
        return request<void>(
          `/organizations/${organizationId}/bank-submissions/${bankSubmissionId}`,
          { method: 'post' }
        );
      },
      approveBankSubmissionRequest(
        organizationId: string,
        bankSubmissionId: string,
        payload: { requiredFilesUploaded: boolean }
      ) {
        return request<void>(
          `/organizations/${organizationId}/bank-submissions/${bankSubmissionId}`,
          { method: 'put', data: payload }
        );
      },
      rejectBankSubmission(organizationId: string, bankSubmissionId: string) {
        return request<BankSubmission>(
          `/organizations/${organizationId}/bank-submissions/${bankSubmissionId}`,
          { method: 'delete' }
        );
      },
      getRiskAndCompliance(id: string) {
        return request<RiskAndCompliance>(
          `/organizations/${id}/risk-and-compliance`
        );
      },
      completeRiskAssessment(organizationId: string) {
        return request<RiskAssessmentCompleted>(
          `/organizations/${organizationId}/risk-assessment`,
          { method: 'post' }
        );
      },
      revertOrganizationStatus(
        organizationId: string,
        newOrganizationStatus: OrganizationStatus
      ) {
        return request<Onboarding>(
          `/organizations/${organizationId}/onboarding/revert-organization-status`,
          { method: 'post', data: { newOrganizationStatus } }
        );
      },
      completePreCheck(organizationId: string) {
        return request<void>(
          `/organizations/${organizationId}/compliance/company/pre-check/complete`,
          { method: 'put' }
        );
      },
      completeComplianceCheck(organizationId: string) {
        return request<ComplianceCheckCompleted>(
          `/organizations/${organizationId}/compliance-check`,
          { method: 'post' }
        );
      },
      getPaymentFrequency(id: string) {
        return request<PaymentFrequency>(`/organizations/${id}/pmt-frequency`);
      },
      updateMinPaymentFrequency(
        orgId: string,
        paymentFrequency: PaymentFrequencyType
      ) {
        return request<PaymentFrequency>(
          `/organizations/${orgId}/min-pmt-frequency`,
          { method: 'put', data: { paymentFrequency } }
        );
      },
      updateCurrPaymentFrequency(
        orgId: string,
        paymentFrequency: PaymentFrequencyType,
        paymentFrequencyCutoffDay: number | null
      ) {
        return request<PaymentFrequency>(
          `/organizations/${orgId}/pmt-frequency`,
          {
            method: 'put',
            data: { paymentFrequency, paymentFrequencyCutoffDay },
          }
        );
      },
      getAdditionalInformations(id: string) {
        return request<AdditionalInfo>(`/organizations/${id}/additional-info`);
      },
      updateCanSetBillingAccountAsPrefund(
        orgId: string,
        canSetBillingAccountAsPrefund: boolean
      ) {
        return request<AdditionalInfo>(
          `/organizations/${orgId}/additional-info/can-set-billing-account-as-prefund`,
          { method: 'put', data: { canSetBillingAccountAsPrefund } }
        );
      },
      getCreditProfile(orgId: string) {
        return request<CreditProfile>(`/organizations/${orgId}/credit-profile`);
      },
      updateCustomerSummary(orgId: string, data: CustomerSummaryPayload) {
        return request<CustomerSummary>(
          `/organizations/${orgId}/credit-profile/customer-summary`,
          {
            method: 'put',
            data,
          }
        );
      },
      updateAgencyProfile(orgId: string, data: AgencyProfilePayload) {
        return request<AgencyProfile>(
          `/organizations/${orgId}/credit-profile/agency-profile`,
          {
            method: 'put',
            data,
          }
        );
      },
      updateCreditAssessmentSummary(
        orgId: string,
        data: CreditAssessmentSummaryPayload
      ) {
        return request<CreditAssessmentSummary>(
          `/organizations/${orgId}/credit-assessment/summary`,
          {
            method: 'put',
            data,
          }
        );
      },
      getCreditAssessment(orgId: string) {
        return request<CreditAssessment>(
          `/organizations/${orgId}/credit-assessment`
        );
      },
      getCalculatedSelfDeclaredEnterpriseSize(params: {
        selfDeclaredAnnualRevenue: string | null;
        selfDeclaredBalanceSheet: string | null;
        selfDeclaredNumberOfEmployees: string | null;
      }) {
        return request<{ enterpriseSize: string }>(
          '/organizations/credit-static-data/calculate-self-declared-enterprise-size',
          {
            params,
          }
        );
      },
      updateCreditAssessmentLimits(
        orgId: string,
        data: CreditAssessmentLimitsPayload
      ) {
        return request<CreditAssessmentLimits>(
          `/organizations/${orgId}/credit-assessment/limits`,
          {
            method: 'put',
            data,
          }
        );
      },
      updateCreditAssessmentUnderwritingCase(
        orgId: string,
        data: CreditAssessmentUnderwritingCasePayload
      ) {
        return request<CreditAssessmentUnderwritingCase>(
          `/organizations/${orgId}/credit-assessment/underwriting-case`,
          {
            method: 'put',
            data,
          }
        );
      },
      updateCardAccountPaymentSettings(
        orgId: string,
        cardAccountId: string,
        data: CardAccountPaymentSettingsPayload
      ) {
        return request<CardAccount>(
          `/organizations/${orgId}/credit-assessment/bill-payment-settings/card-account/${cardAccountId}`,
          {
            method: 'put',
            data,
          }
        );
      },
      getComplianceStaticData() {
        return request<ComplianceStaticData>(
          `/organizations/compliance-static-data`
        );
      },
      getCreditStaticData() {
        return request<CreditStaticData>(`/organizations/credit-static-data`);
      },
      getNaceIndustryCodeSearch(naceCodePrefix: string) {
        return request<{ naceIndustries: NaceIndustryCode[] }>(
          '/organizations/industry-codes/nace/search',
          {
            params: {
              naceCodePrefix,
            },
          }
        );
      },
      getInternalIndustries() {
        return request<{ internalIndustryTypes: InternalIndustryType[] }>(
          `/organizations/credit-static-data/internal-industry`
        );
      },
      getSpanishProvinces() {
        return request<{ spanishProvinces: SpanishProvince[] }>(
          `/organizations/compliance-static-data/spanish-provinces`
        );
      },
      getComplianceCompany(orgId: string) {
        return request<ComplianceCompany>(
          `/organizations/${orgId}/compliance/company`
        );
      },
      updateMasterData(orgId: string, data: MasterDataPayload) {
        return request<MasterData>(
          `/organizations/${orgId}/compliance/company/master-data`,
          {
            method: 'put',
            data,
          }
        );
      },
      updateComplianceRating(orgId: string, data: ComplianceRatingPayload) {
        return request<ComplianceRating>(
          `/organizations/${orgId}/compliance/company/compliance-rating`,
          {
            method: 'put',
            data,
          }
        );
      },
      getOrganizationsStaticData() {
        return request<OrganizationsStaticData>('/organizations/static-data');
      },
      getCardAccountCurrencies() {
        return request<{ currencies: CardAccountCurrency[] }>(
          '/organizations/currencies'
        );
      },
      getAvailableCardAccountCurrencies(orgId: string) {
        return request<{ currencies: CardAccountCurrency[] }>(
          `/organizations/${orgId}/card-accounts/available-currencies`
        );
      },
      getFirmographics(organizationId: string) {
        return request<Firmographics>(
          `/organizations/${organizationId}/credit-profile/firmographics`
        );
      },
      updateFirmographicsSize(
        organizationId: string,
        data: Partial<FirmographicsSizePayload>
      ) {
        return request<Firmographics>(
          `/organizations/${organizationId}/credit-profile/firmographics/size`,
          { method: 'put', data }
        );
      },
      updateFirmographicsSegmentation(
        organizationId: string,
        data: Partial<FirmographicsSegmentationPayload>
      ) {
        return request<Firmographics>(
          `/organizations/${organizationId}/credit-profile/firmographics/segmentation`,
          { method: 'put', data }
        );
      },
      updateFirmographicsFunding(
        organizationId: string,
        data: Partial<FirmographicsFundingPayload>
      ) {
        return request<Firmographics>(
          `/organizations/${organizationId}/credit-profile/firmographics/funding`,
          { method: 'put', data }
        );
      },
      requestReportFromCrefo(organizationId: string) {
        return request<void>(`/crefo/${organizationId}`);
      },
      getCrefoReports(organizationId: string) {
        return request<CrefoReportList>(`/crefo/${organizationId}/reports`, {});
      },
      getCrefoReportContents(id: string) {
        return request<{ data: string }>(`/crefo/report/${id}`);
      },
      getMonitoringStatus(companyId: string, provider: ExternalReportProvider) {
        return request<{ active: boolean }>(
          `/data/monitoring/${companyId}?provider=${provider}`
        );
      },
      cancelCrefoMonitoring(orgId: string) {
        return request<void>(`/crefo/${orgId}/cancel-report-monitoring`, {
          method: 'post',
        });
      },
      cancelDnbMonitoring(duns: string) {
        return request<void>(`/data/monitoring/${duns}/disable?providoer=DNB`);
      },
      requestExternalReport(companyId: string) {
        return request<{ reportsPaths: ExternalReport[] }>(
          `/data/company-report/${companyId}`
        );
      },
      getExternalReports(companyId: string) {
        return request<{ reportsPaths: ExternalReport[] }>(
          `/data/company-report/${companyId}/reports`
        );
      },
      getExternalReportContents(companyId: string, reportId: string) {
        return request<{ data: string }>(
          `/data/company-report/${companyId}/${reportId}`
        );
      },
      getDnbReportRawData(duns: string) {
        return request<{ [key: string]: any }>(`/data/dnb/raw-data/${duns}`);
      },
      createLimitChangeRequest(data: CreateLimitChangeRequestPayload) {
        return request<LimitChangeRequest>('/cards/limit-change-requests', {
          method: 'post',
          data,
        });
      },
      getLimitChangeRequest(id: string) {
        return request<LimitChangeRequest>(
          `/cards/limit-change-requests/${id}`
        );
      },
      cancelLimitChangeRequest(id: string) {
        return request<void>(`/cards/limit-change-requests/${id}/cancel`, {
          method: 'put',
        });
      },
      cancelCardRequest(id: string) {
        return request<void>(`/cards/${id}/requests/cancel`, {
          method: 'put',
        });
      },
      rejectCardRequest(id: string) {
        return request<void>(`/cards/${id}/requests/reject`, {
          method: 'put',
        });
      },
      approveCardRequest(id: string) {
        return request<void>(`/cards/${id}/requests/approve`, {
          method: 'put',
        });
      },
      approveLimitChangeRequest(
        id: string,
        data: ApproveLimitChangeRequestPayload
      ) {
        return request<void>(`/cards/limit-change-requests/${id}/approve`, {
          method: 'put',
          data,
        });
      },
      rejectLimitChangeRequest(id: string) {
        return request<void>(`/cards/limit-change-requests/${id}/reject`, {
          method: 'put',
        });
      },
      getPaymentDeferment(id: string) {
        return request<PaymentDeferment>(`/organizations/${id}/pmt-deferment`);
      },
      updatePaymentDeferment(id: string, paymentDefermentInDays: number) {
        return request<Organization>(`/organizations/${id}/pmt-deferment`, {
          method: 'put',
          data: { paymentDefermentInDays },
        });
      },
      setBankConnectionRequired(id: string, required: boolean) {
        return request<Organization>(
          `/organizations/${id}/bank-connection-required`,
          {
            method: 'put',
            data: { required },
          }
        );
      },
      updateOrgMissingReceiptNotifications(id: string, enabled: boolean) {
        return request<Organization>(
          `/organizations/${id}/toggle-missing-receipt-notifications`,
          {
            method: 'put',
            data: { enabled },
          }
        );
      },
      getExpectedMonthlySpend(orgId: string) {
        return request<{
          organizationId: string;
          expectedMonthlySpend: Money | null;
        }>(`/organizations/${orgId}/expected-monthly-spend`);
      },
      updateExpectedMonthlySpend(orgId: string, expectedMonthlySpend: Money) {
        return request<{ organizationId: string; expectedMonthlySpend: Money }>(
          `/organizations/${orgId}/expected-monthly-spend`,
          {
            method: 'put',
            data: { expectedMonthlySpend },
          }
        );
      },
      getFxTransferFeePercentage(orgId: string) {
        return request<{
          fxTransferFeePercentage: number | null;
          organizationId: string;
        }>(`/organizations/${orgId}/fx-transfer-fee-percentage`);
      },
      updateFxTransferFeePercentage(
        orgId: string,
        fxTransferFeePercentage: number
      ) {
        return request<{
          fxTransferFeePercentage: number;
          organizationId: string;
        }>(`/organizations/${orgId}/fx-transfer-fee-percentage`, {
          method: 'put',
          data: { fxTransferFeePercentage },
        });
      },
      getBanksForConnection(params: object) {
        return request<BankForConnectionPageableList>(
          `/bank-connections/bank-search`,
          { params }
        );
      },
      getMemberships(id: string, status: string) {
        return request<MembershipList>(`/members/${id}/memberships`, {
          params: { status },
        });
      },
      getPartialMembers(params: GetPartialMembersParams) {
        return request<PartialMemberPageableList>(`/members/shortened`, {
          params,
        });
      },
      getMembers(params: GetMembersParams) {
        return request<MemberPageableList>(`/members/`, { params });
      },
      getMember(organizationId: string, id: string) {
        return request<MemberDetails>(`/members/${id}`, {
          params: { organizationId },
        });
      },
      getMemberCount(organizationId: string) {
        return request<MemberCount>('/members/members-count', {
          params: { organizationId },
        });
      },
      inviteMember(data: InviteMemberPayload) {
        return request<MemberDetails>(`/members/`, { method: 'post', data });
      },
      updateMember(id: string, data: object) {
        return request(`/members/${id}`, { method: 'put', data });
      },
      updateMemberRoles(id: string, data: object) {
        return request(`/members/${id}/roles`, {
          method: 'put',
          data,
        });
      },
      updateMemberTeams(id: string, data: UpdateMemberTeamsPayload) {
        return request<void>(`/members/${id}/teams`, {
          method: 'post',
          data,
        });
      },
      resendMemberInvite(organizationId: string, id: string) {
        return request(`/members/${id}/resend-invitation-email`, {
          method: 'post',
          data: { organizationId },
        });
      },
      getPairedDevice(id: string) {
        return request<Device>(`/members/${id}/devices`);
      },
      unpairDevice(id: string) {
        return request(`/members/${id}/devices/unpair`, {
          method: 'post',
        });
      },
      updateSecurityKey(organizationId: string, id: string) {
        return request(`/members/${id}/mfa`, {
          method: 'put',
          data: { organizationId },
        });
      },
      getSecurityKeyExclusiveCount(organizationId: string) {
        return request<{ count: number }>(
          '/members/security-key-exclusive-count',
          {
            params: { organizationId },
          }
        );
      },
      getNotificationSettingsCategorized(
        organizationId: string,
        memberId: string
      ) {
        return request<NotificationSettingsCategorized>(
          `/notifications/member/${memberId}/categories`,
          {
            params: { organizationId },
          }
        );
      },
      updateNotificationSettings(
        organizationId: string,
        memberId: string,
        data: NotificationSetting[]
      ) {
        return request<void>(`/notifications/member/${memberId}/categories`, {
          method: 'put',
          params: { organizationId },
          data,
        });
      },
      updateLegalRepType(id: string, data: object) {
        return request(`/members/${id}/legal-rep`, {
          method: 'put',
          data,
        });
      },
      getSmsLogs(memberId: string) {
        return request<SmsLog[]>(`/members/${memberId}/sms-logs/all`);
      },
      activateMember(organizationId: string, id: string) {
        return request(`/members/${id}/activation`, {
          method: 'post',
          data: { organizationId },
        });
      },
      deactivateMember(organizationId: string, id: string) {
        return request(`/members/${id}/deactivate`, {
          method: 'post',
          data: { organizationId },
        });
      },
      reactivateMember(organizationId: string, id: string) {
        return request<void>(`/members/${id}/reactivate`, {
          method: 'post',
          data: { organizationId },
        });
      },
      changeMemberLanguage(id: string, languageCode: SupportedWebAppLanguage) {
        return request(`/members/${id}/language`, {
          method: 'put',
          data: { languageCode },
        });
      },
      changeMemberEmail(id: string, data: object) {
        return request(`/members/${id}/email`, {
          method: 'put',
          data,
        });
      },
      resetMemberPassword(id: string) {
        return request(`/members/${id}/reset-password`, {
          method: 'post',
        });
      },
      syncMfa(id: string) {
        return request<MemberDetails>(`/members/${id}/sync`, {
          method: 'patch',
        });
      },
      getMyTeams(organizationId: string) {
        return request<MinimalTeam[]>(`/teams/minimal/${organizationId}`);
      },
      getTeams(params: GetTeamsParams) {
        // default pagination limit on the backend is 100 teams
        return request<TeamPageableList>('/teams', {
          params,
        });
      },
      getTeamCount(organizationId: string) {
        return request<{ count: number }>('/teams/count', {
          params: { organizationId },
        });
      },
      getTeamTransactionAssignments(organizationId: string, teamIds: string) {
        return request<TeamTransactionAssignmentList>(
          `/accounting/teams/transaction-assignments/`,
          {
            params: { organizationId, teamIds },
          }
        );
      },
      createTeam(data: CreateTeamPayload) {
        return request<Team>('/teams', {
          method: 'post',
          data,
        });
      },
      updateTeam(id: string, data: UpdateTeamPayload) {
        return request<Team>(`/teams/${id}`, {
          method: 'put',
          data,
        });
      },
      updateTeamMembers(id: string, data: UpdateTeamMembersPayload) {
        return request<Team>(`/teams/${id}/members`, {
          method: 'post',
          data,
        });
      },
      updateTeamPermissions(id: string, data: UpdateTeamPermissionsPayload) {
        return request<Team>(`/teams/${id}/permissions`, {
          method: 'put',
          data,
        });
      },
      updateTeamBudget(id: string, budget: Money | null) {
        return request<Team>(`/teams/${id}/budget`, {
          method: 'put',
          data: { budget },
        });
      },
      deactivateTeam(id: string) {
        return request<Team>(`/teams/${id}/deactivate`, { method: 'post' });
      },
      deleteTeam(id: string) {
        return request<void>(`/teams/${id}`, { method: 'delete' });
      },
      getCardConfigSettings(organizationId: string) {
        return request<CardConfigSettingList>('/cards/card-configs', {
          params: { organizationId },
        });
      },
      getActivatedAndAvailableTpcConfigs(organizationId: string) {
        return request<ActivatedAndAvailableTpcConfigs>(
          `/travel-card-configs/${organizationId}`
        );
      },
      updateActivatedTpcConfigs(
        organizationId: string,
        data: { configs: string[] }
      ) {
        return request<ActivatedAndAvailableTpcConfigs>(
          `/travel-card-configs/${organizationId}`,
          {
            method: 'put',
            data,
          }
        );
      },
      getCardDesigns(organizationId?: string) {
        return request<CardDesignList>('/cards/card-designs', {
          params: { organizationId },
        });
      },
      issueCard(data: IssueCardPayload) {
        return request<Card>('/cards', {
          method: 'post',
          data,
        });
      },
      issueCardSync(data: IssueCardPayload) {
        return request<Card>('/cards/instant-issue', {
          method: 'post',
          data,
        });
      },
      getCard(id: string) {
        return request<Card>(`/cards/${id}`);
      },
      getTotpPci(cardId: string, traceId: string) {
        return request<string>(`/card-details/widget/${cardId}/otp`, {
          baseURL:
            env.NODE_ENV === 'development' ? '' : env.REACT_APP_PCI_API_URL,
          headers: {
            'Pliant-Trace-Id': traceId,
          },
        });
      },
      getPlatformFeeCard(organizationId: string) {
        return request<Card>(`/cards/platform-fee/${organizationId}`);
      },
      changePlatformFeeCardLimits(id: string, data: ChangeCardLimitsPayload) {
        return request<Card>(`/cards/platform-fee/${id}/limits`, {
          method: 'put',
          data,
        });
      },
      getCardHistory(id: string, params: GetCardHistoryParams) {
        return request<CardHistoryEntryPageableList>(`/cards/${id}/history`, {
          params,
        });
      },
      activateCard(id: string, accessCode: string) {
        return request<Card>(`/cards/${id}/activate`, {
          method: 'post',
          data: { accessCode },
        });
      },
      getCardControlRegions(partnerId: PartnerIdType) {
        return request<CardControlRegion[]>('/cards/controls/regions', {
          params: { partnerId },
        });
      },
      updateCardControls(
        cardId: string,
        data: UpdateCardControlsRequestPayload
      ) {
        return request<Card['cardControls']>(`/cards/${cardId}/card-controls`, {
          method: 'put',
          data,
        });
      },
      updateCardValidityPeriod(
        cardId: string,
        data: {
          validFrom: string;
          validTo: string;
          validTimezone: string;
        }
      ) {
        return request<Card>(`/cards/${cardId}/validity`, {
          method: 'put',
          data,
        });
      },
      updateCardProject(cardId: string, projectId: string | null) {
        return request<Card>(`/cards/${cardId}/project`, {
          method: 'put',
          data: { projectId },
        });
      },
      createCardRequest(data: CreateCardRequestPayload) {
        return request<CardRequest>('/cards/requests', {
          method: 'post',
          data,
        });
      },
      getCardRequest(id: string) {
        return request<CardRequest>(`/cards/${id}/requests`);
      },
      changeCardLimits(id: string, data: ChangeCardLimitsPayload) {
        return request<Card>(`/cards/${id}/limits`, {
          method: 'put',
          data,
        });
      },
      updateCard(id: string, data: UpdateCardPayload) {
        return request<Card>(`/cards/${id}`, {
          method: 'patch',
          data,
        });
      },
      renewCard(
        id: string,
        reason: CardReplacementReason,
        expiryPeriodMonths: number
      ) {
        return request<Card>(`/cards/${id}/renew`, {
          method: 'post',
          data: {
            terminateCardReason: reason,
            expiryPeriodMonths,
          },
        });
      },
      terminateCard(id: string, reason: CardTerminationReason) {
        return request<Card>(`/cards/${id}/terminate`, {
          method: 'post',
          data: { reason },
        });
      },
      getCardPin(id: string) {
        return request<Pin>(`/cards/${id}/pin`);
      },
      updateCardPin(id: string, newPin: string) {
        return request(`/cards/${id}/pin`, { method: 'put', data: { newPin } });
      },
      unlockCardPin(id: string) {
        return request<Card>(`/cards/${id}/unlock-locked-pin`, {
          method: 'post',
        });
      },
      updateCardTeam(id: string, teamId: string | null) {
        return request<Card>(`/cards/${id}/team`, {
          method: 'put',
          data: { teamId },
        });
      },
      getCards(params: GetCardsParams) {
        return request<CardPageableList>('/cards', { params });
      },
      getCardCount(organizationId: string) {
        return request<CardCount>('/cards/cards-count', {
          params: { organizationId },
        });
      },
      addCardToCircula(orgId: string, cardId: string) {
        return request<void>(
          `/circula/organizations/${orgId}/cards/${cardId}`,
          { method: 'post' }
        );
      },
      removeCardFromCircula(orgId: string, cardId: string) {
        return request<void>(
          `/circula/organizations/${orgId}/cards/${cardId}`,
          { method: 'delete' }
        );
      },
      pass3dsAuthentication(data: ThreeDsAuthRequest) {
        return request<void>('/cards/webhooks/3ds/notify-validate', {
          method: 'post',
          data,
        });
      },
      getGlobalTransactions(params: GetGlobalTransactionsParams) {
        return request<TransactionPageableList>('/export/transactions/global', {
          params,
        });
      },
      getGlobalTransaction(id: string) {
        return request<Transaction>(`/export/transactions/global/${id}`);
      },
      getTransactions(params: GetTransactionsParams) {
        return request<TransactionPageableList>('/transactions', { params });
      },
      getTransactionsBatch(params: {
        transactionIds: string;
        page: number;
        limit: number;
      }) {
        return request<TransactionPageableList>('/transactions-batch', {
          params,
        });
      },
      getTransactionsIds(params: object) {
        return request<{ transactionIds: string[] }>(
          `/transaction-search/transaction-ids`,
          { params }
        );
      },
      getTransaction(id: string) {
        return request<Transaction>(`/transactions/${id}`);
      },
      updateTransaction(id: string, comment: string) {
        return request(`/transactions/${id}`, {
          method: 'patch',
          data: { comment },
        });
      },
      updateTransactionsReview(data: UpdateTransactionsReviewPayload) {
        return request<void>(`/entry-review/transactions/review`, {
          method: 'patch',
          data,
        });
      },
      updateTransactionDispute(id: string, disputed: boolean) {
        return request(`/transactions/${id}/dispute`, {
          method: 'patch',
          data: { disputed },
        });
      },
      updateTransactionTeam(id: string, teamId: string | null) {
        return request(`/transactions/${id}/team`, {
          method: 'patch',
          data: { teamId },
        });
      },
      updateTransactionsExport(data: UpdateTransactionsExportPayload) {
        return request<{ accountingExportId: string }>('/transactions/export', {
          method: 'post',
          data,
        });
      },
      getTransactionsDownloadFile(params: object) {
        return request<string>('/transactions/download', {
          method: 'get',
          params,
          responseType: 'arraybuffer',
          fullResponse: true,
        });
      },
      getReceipts(transactionId: string) {
        return request<ReceiptList>(`/receipts`, { params: { transactionId } });
      },
      getTransactionReceipts(
        organizationId: string,
        transactionIds: string[],
        memberId?: string
      ) {
        return request<string>('/transaction-receipts', {
          method: 'post',
          data: { organizationId, memberId, transactionIds },
          responseType: 'arraybuffer',
          fullResponse: true,
        });
      },
      uploadReceipt(
        transactionId: string,
        file: File,
        onUploadProgress: (percent: number) => void
      ) {
        const data = new FormData();
        data.append('file', file);
        data.append('transactionId', transactionId);
        return request<Receipt>(`/receipts`, {
          method: 'post',
          headers: { 'Content-Type': 'multipart/form-data' },
          data,
          onUploadProgress(progressEvent) {
            const { loaded, total } = progressEvent;
            if (total) {
              const percent = Math.round((loaded * 100) / total);
              onUploadProgress(percent);
            }
          },
        });
      },
      replaceReceipt(
        receiptId: string,
        file: File,
        onUploadProgress: (percent: number) => void
      ) {
        const data = new FormData();
        data.append('file', file);
        return request<Receipt>(`/receipts/${receiptId}/replace`, {
          method: 'put',
          headers: { 'Content-Type': 'multipart/form-data' },
          data,
          onUploadProgress(progressEvent) {
            const { loaded, total } = progressEvent;
            if (total) {
              const percent = Math.round((loaded * 100) / total);
              onUploadProgress(percent);
            }
          },
        });
      },
      getReceiptThumbnail(id: string) {
        return request<string>(`/receipts/${id}/thumbnail`, {
          responseType: 'arraybuffer',
          transformResponse: transformArrayBufferToString,
        });
      },
      getReceiptOriginal(id: string) {
        return request<string>(`/receipts/${id}/original`, {
          responseType: 'arraybuffer',
          transformResponse: transformArrayBufferToString,
          fullResponse: true,
        });
      },
      getEReceiptOriginal(id: string) {
        return request<string>(`/receipts/${id}/original/ereceipt`, {
          responseType: 'arraybuffer',
          transformResponse: transformArrayBufferToString,
          fullResponse: true,
        });
      },
      deleteReceipt(id: string) {
        return request(`/receipts/${id}`, { method: 'delete' });
      },
      rejectReceipt(
        id: string,
        reason: ReceiptRejectionReason,
        comment: string
      ) {
        return request<Receipt>(`/receipts/${id}/reject`, {
          method: 'post',
          data: { reason, comment },
        });
      },
      updateReceiptNeeded(transactionId: string, receiptNeeded: boolean) {
        return request('/receipts', {
          method: 'patch',
          data: { transactionId, receiptNeeded },
        });
      },
      updateReceipt(data: UpdateReceiptPayload) {
        return request('/receipts/update', {
          method: 'patch',
          data,
        });
      },
      getAccountingTransactions(transactionId: string) {
        return request<AccountingTransactionList>(
          `/accounting/transactions/${transactionId}/accounting-transactions`
        );
      },
      updateAccountingTransactions(
        transactionId: string,
        organizationId: string,
        accountingTransactions: UpdateAccountingTransactionsPayload
      ) {
        return request<AccountingTransactionList>(
          `/accounting/transactions/${transactionId}/accounting-transactions`,
          {
            method: 'put',
            data: {
              organizationId,
              accountingTransactions,
            },
          }
        );
      },
      getExportFlowTransactions(params: object) {
        return request<ExportFlowTransactionList>('/export-flow/transactions', {
          params,
        });
      },
      getExportFlowSummary(organizationId: string, transactionIds: string[]) {
        return request<ExportFlowSummary>('/export-flow/summary', {
          method: 'post',
          data: { organizationId, transactionIds },
        });
      },
      getExportFlowFile(params: GetExportFlowFileParams) {
        return request<string>('/export-flow/download', {
          params,
          responseType: 'arraybuffer',
          fullResponse: true,
        });
      },
      downloadExportFlowFile(data: DownloadExportFlowFilePayload) {
        return request<string>('/export-flow/download', {
          method: 'post',
          data,
          responseType: 'arraybuffer',
          fullResponse: true,
        });
      },
      downloadFile(
        url: string,
        params?: object,
        axiosConfig?: AxiosRequestConfig
      ) {
        return request<string>(url, {
          params,
          ...axiosConfig,
          fullResponse: true,
        });
      },
      getAccountEntries(params: GetAccountEntriesParams) {
        return request<AccountEntryPageableList>('/tx-export/account-entries', {
          params,
        });
      },
      getAccountEntriesIds(params: object) {
        return request<{ transactionIds: string[] }>(
          `/tx-export/account-entries/transactions-ids`,
          { params }
        );
      },
      getAccountEntry(id: string) {
        return request<AccountEntry>(`/tx-export/account-entries/${id}`);
      },
      getAccountEntriesFile(params: GetAccountEntriesCsvParams) {
        return request<string>('/tx-export/account-entries/download', {
          method: 'get',
          params,
          responseType: 'arraybuffer',
          fullResponse: true,
        });
      },
      getBillingStmt(params: GetBillingStmtParams) {
        return request<string>(`/documents/billing-stmt`, {
          method: 'post',
          params,
          responseType: 'arraybuffer',
          fullResponse: true,
        });
      },
      getBalanceStatement(
        cardAccountId: string,
        params: { fromBookingDate?: string; toBookingDate?: string }
      ) {
        return request<string>(
          `/documents/${cardAccountId}/balance-statement`,
          {
            method: 'post',
            params,
            responseType: 'arraybuffer',
            fullResponse: true,
          }
        );
      },
      getBills(organizationId: string, allBills = false) {
        return request<BillList>('/bills', {
          params: { organizationId, allBills },
        });
      },
      getBillsCount(organizationId: string) {
        return request<number>('/bills/count', { params: { organizationId } });
      },
      getBillPayments(billId: string) {
        return request<Payment[]>(`/bills/${billId}/payments`);
      },
      getOrganizationAccountStatement(statementId: string) {
        return request<OrganizationAccountStatement>(
          `/organization-account-statements/${statementId}`
        );
      },
      getOrganizationAccountStatements(
        organizationId: string,
        cardAccountId?: string
      ) {
        return request<OrganizationAccountStatementsResponse>(
          `/organization-account-statements`,
          { params: { organizationId, cardAccountId } }
        );
      },
      getOrganizationAccountStatementsPayments(accountStatementId: string) {
        return request<{ payments: OrganizationAccountStatementPayment[] }>(
          `/organization-account-statements/${accountStatementId}/payments`
        );
      },
      getOrganizationBillPayments(
        organizationId: string,
        page?: number,
        limit?: number
      ) {
        return request<OrganizationBillPaymentsPageableResponse>(
          `/organization-bill-payments`,
          { params: { organizationId, page, limit } }
        );
      },
      getBillReceipts(billId: string) {
        return request<string>(`/bill-receipts/${billId}`, {
          responseType: 'arraybuffer',
          fullResponse: true,
        });
      },
      getBillPaymentsCsv(params: GetBillPaymentsCsvParams) {
        return request<string>('/bill-payments-csv', {
          params,
          responseType: 'arraybuffer',
          fullResponse: true,
        });
      },
      getBillPaymentsXml(params: GetBillPaymentsXmlParams) {
        return request<string>('/bill-payments-xml', {
          params,
          responseType: 'arraybuffer',
          fullResponse: true,
        });
      },
      getSettlementGroups() {
        return request<SettlementGroupList>(`/settlement-groups`);
      },
      getProgramSettlements(params: object) {
        return request<ProgramSettlementPageableList>('/program-settlements', {
          params,
        });
      },
      getProgramSettlement(id: string) {
        return request<ProgramSettlement>(`/program-settlements/${id}`);
      },
      getOrgSettlements(params: object) {
        return request<SettlementPageableList>('/settlements', { params });
      },
      getOrgSettlement(settlementId: string) {
        return request<Settlement>(`/settlements/${settlementId}`);
      },
      getSettlementPayments(originatorId: string) {
        return request<SettlementPaymentList>('/payments', {
          params: { originatorId },
        });
      },
      getProgramSettlementPaymentSummary(programPaymentId: string) {
        return request<ProgramSettlementPaymentDetails>(
          '/payments/program-settlement-payment',
          { params: { programPaymentId } }
        );
      },
      getProgramSettlementPaymentsFilesDetails(programSettlementId: string) {
        return request<ProgramSettlementPaymentsFile[]>(
          '/payments/settlement-payments/file-details',
          { params: { programSettlementId } }
        );
      },
      submitProgramSettlementPaymentsFiles(
        paymentFileIds: string[],
        programSettlementId: string
      ) {
        return request<ProgramSettlementPaymentsFile>(
          '/payments/settlement-payments/submission',
          { method: 'post', data: { paymentFileIds, programSettlementId } }
        );
      },
      moveProgramSettlementPaymentToPending(programSettlementFileId: string) {
        return request<void>(
          '/payments/move-program-settlement-payment-to-pending',
          { method: 'post', data: { programSettlementFileId } }
        );
      },
      resubmitPaymentFile(fileName: string) {
        return request<{
          paymentFileId: string;
          submittedAt: string;
        }>('/payments/re-submit-payment-file', {
          method: 'post',
          params: { fileName },
        });
      },
      downloadPaymentFile(fileName: string) {
        return request<string>(`/payments/files`, {
          params: { fileName },
          responseType: 'arraybuffer',
          fullResponse: true,
        });
      },
      getReceivableRepayments(params: GetReceivableRepaymentsParams) {
        return request<ReceivableRepaymentsPageableList>(
          '/receivable-repayments',
          { params }
        );
      },
      getReceivableRepayment(id: string) {
        return request<ReceivableRepayment>(`/receivable-repayments/${id}`);
      },
      getRepaymentAmountsPerOrganisation(id: string, params: { page: number }) {
        return request<ReceivableRepaymentAmountPageableList>(
          `/receivable-repayments/${id}/details`,
          { params }
        );
      },
      submitReceivableRepayment(id: string) {
        return request<void>('/receivable-repayments/submission', {
          method: 'post',
          data: { value: id },
        });
      },
      getBillPaymentBatches(params: GetBillPaymentBatchesParams) {
        return request<BillPaymentBatchPageableList>('/bill-payment-batches', {
          params,
        });
      },
      getBillPaymentBatch(id: string) {
        return request<BillPaymentBatch>(`/bill-payment-batches/${id}`);
      },
      getBillPaymentBatchPayments(batchId: string) {
        return request<BatchPaymentList>(
          `/bill-payment-batches/${batchId}/payments`
        );
      },
      submitDirectDebitForBillPayments(paymentFileId: string) {
        return request('/bill-payments/submit-direct-debit', {
          method: 'post',
          data: { paymentFileId },
        });
      },
      createPayoutPayment(data: CreatePayoutPaymentPayload) {
        return request<Payment>(`/bill-payments/payout`, {
          method: 'post',
          data,
        });
      },
      failDelayedBillPayment(paymentId: string) {
        return request(`/bill-payments/fail-delayed-payment`, {
          method: 'post',
          params: { paymentId },
        });
      },
      discardDelayedBillPayment(paymentId: string) {
        return request(`/bill-payments/discard`, {
          method: 'post',
          params: { paymentId },
        });
      },
      convertBillPaymentToWireTransfer(paymentId: string) {
        return request(`/bill-payments/convert-to-wire-transfer`, {
          method: 'post',
          params: { paymentId },
        });
      },
      getBillPaymentsBankAccounts(params: GetBillPaymentsBankAccountsParams) {
        return request<BillPaymentsBankAccountPageableList>(
          '/bill-payments/bank-accounts',
          { params }
        );
      },
      getBillPaymentsBankAccount(id: string) {
        return request<BillPaymentsBankAccount>(
          `/bill-payments/bank-accounts/${id}`
        );
      },
      getBillPaymentsByBankAccountId(
        id: string,
        params: { billPaymentTypes: string }
      ) {
        return request<{ payments: Payment[] }>(
          `/bill-payments/bank-accounts/${id}/payments`,
          { params }
        );
      },
      approveBillPayment(paymentId: string) {
        return request<Payment>(`/bill-payments/approve`, {
          method: 'post',
          params: { paymentId },
        });
      },
      rejectBillPayment(paymentId: string) {
        return request<Payment>(`/bill-payments/reject`, {
          method: 'post',
          params: { paymentId },
        });
      },
      getSettlementTransactions(params: object) {
        return request<SettlementTransactionPageableList>(
          '/account-statements/settlement-transactions',
          { params }
        );
      },
      getProgramSettlementTransactions(params: object) {
        return request<SettlementTransactionPageableList>(
          '/account-statements/program-settlement-transactions',
          { params }
        );
      },
      downloadAccountStatements(
        accountTransactionId?: string,
        paymentId?: string
      ) {
        return request<string>(`/account-statements/files`, {
          params: {
            accountTransactionId,
            paymentId,
          },
          responseType: 'arraybuffer',
          fullResponse: true,
        });
      },
      getProcessingAccounts(organizationId: string, cardAccountId: string) {
        return request<{ processingAccounts: ProcessingAccount[] }>(
          `/organizations/${organizationId}/card-accounts/${cardAccountId}/processing-accounts`
        );
      },
      getCashbackRedemptionBatches(params: object) {
        return request<CashbackRedemptionBatchPageableList>(
          '/cashback-redemption-batches',
          { params }
        );
      },
      getCashbackRedemptionBatch(batchId: string) {
        return request<CashbackRedemptionBatch>(
          `/cashback-redemption-batches/${batchId}`
        );
      },
      submitCashbackRedemptionBatch(batchId: string) {
        return request<CashbackRedemptionBatch>(
          `/cashback-redemption-batches/${batchId}/submit`,
          { method: 'post' }
        );
      },
      getCashbackRedemptions(batchId: string) {
        return request<CashbackRedemptionList>(
          `/cashback-redemption-batches/${batchId}/redemptions`
        );
      },
      getCompensationBatches(params: GetCompensationBatchesParams) {
        return request<CompensationBatchPageableList>('/compensation-batches', {
          params,
        });
      },
      getCompensationBatchDetails(id: string) {
        return request<CompensationBatchDetails>(`/compensation-batches/${id}`);
      },
      getCompensationCertificationProjects(batchId: string) {
        return request<CompensationCertificationProjectList>(
          `/compensation-batches/${batchId}/certification-projects`
        );
      },
      addCompensationCertificationProjects(
        batchId: string,
        data: CompensationCertificationProjectList
      ) {
        return request<CompensationStatementList>(
          `/compensation-batches/${batchId}/certification-projects`,
          { method: 'post', data }
        );
      },
      generateCompensationStatements(batchId: string) {
        return request<void>(
          `/compensation-batches/${batchId}/statements-generation`,
          { method: 'post' }
        );
      },
      getMissingTransactionReceiptCount(params: GetMissingReceiptCountParams) {
        return request<{ count: number }>(
          '/transactions/missing-receipt-count',
          { params }
        );
      },
      getReviewStatusCount(params: GetReviewStatusCountParams) {
        return request<ReviewStatusCount>('/transactions/review-status-count', {
          params,
        });
      },
      getPublicDocuments(organizationId: string, latest: boolean = true) {
        return request<{ documents: DocumentsByGenericTypeMap }>(
          `/documents/public/organization/${organizationId}`,
          {
            params: { latest },
          }
        );
      },
      // @deprecated - will be deleted after onboarding redesign
      getPublicDocumentContentOld(id: string) {
        return request<string>(`/documents/${id}/content`, {
          responseType: 'arraybuffer',
        });
      },
      getPublicDocumentContent(id: string) {
        return request<string>(`/documents/${id}/content`, {
          responseType: 'arraybuffer',
          fullResponse: true,
        });
      },
      getPublicDocumentThumbnail(id: string) {
        return request<string>(`/documents/${id}/thumbnail`, {
          responseType: 'arraybuffer',
          transformResponse: transformArrayBufferToString,
        });
      },
      getCashback(organizationId: string) {
        return request<Cashback>(`/organization-cashback/${organizationId}`);
      },
      getCashbackByCurrency(organizationId: string) {
        return request<CurrencyCashbackByCurrencyMap>(
          `/organization-cashback/${organizationId}/aggregated`
        );
      },
      getMonthlyCashbackProgress(organizationId: string) {
        return request<MonthlyCashbackProgressList>(
          `/organization-cashback/${organizationId}/monthly`
        );
      },
      getCashbackRedemptionBankAccounts(
        organizationId: string,
        currency: string
      ) {
        return request<CashbackRedemptionBankAccountList>(
          `/organization-cashback/${organizationId}/bank-accounts`,
          { params: { currency } }
        );
      },
      getCarbonPrice() {
        return request<CarbonPrice>('/carbon-price');
      },
      redeemCashback(
        organizationId: string,
        data: {
          bankAccountId: string;
          payoutAmount: Money;
          carbonOffsetAmount?: Money;
        }
      ) {
        return request<Cashback>(
          `/organization-cashback/${organizationId}/redeem`,
          { method: 'post', data }
        );
      },
      getHistoryCashbackRedemptions(
        organizationId: string,
        params: GetHistoryCashbackRedemptionsParams
      ) {
        return request<HistoryCashbackRedemptionPageableList>(
          `/redemption-history/${organizationId}`,
          { params }
        );
      },
      updateCashbackThreshold(organizationId: string, threshold: Money) {
        return request<Cashback>(
          `/organization-cashback/${organizationId}/monthly-threshold`,
          {
            method: 'put',
            data: { threshold },
          }
        );
      },
      setCustomCashbackRate(
        organizationId: string,
        data: {
          type: CashbackType;
          rate: number;
          expirationDate: string | null;
        }
      ) {
        return request<Cashback>(
          `/organization-cashback/${organizationId}/cashback-rate`,
          {
            method: 'put',
            data,
          }
        );
      },
      deleteCustomCashbackRate(organizationId: string, type: CashbackType) {
        return request<Cashback>(
          `/organization-cashback/${organizationId}/cashback-rate`,
          { method: 'delete', data: { type } }
        );
      },
      toggleOrgSustainabilityFeature(id: string, enabled: boolean) {
        return request<Organization>(
          `/organizations/${id}/toggle-sustainability-feature`,
          {
            method: 'put',
            data: { enabled },
          }
        );
      },
      getTotalOrgEmissionsByYear(id: string) {
        return request<OrganizationEmission>(`/organization-emissions/${id}`);
      },
      getTransactionEmissions(data: {
        organizationId: string;
        transactionIds: string[];
      }) {
        return request<TransactionEmissionList>('/transaction-emissions', {
          method: 'post',
          data,
        });
      },
      getMemberPermissions(organizationId: string, id: string) {
        return request<MemberPermissions>(`/members/${id}/permissions`, {
          params: { organizationId },
        });
      },
      updateMemberPermissions(
        id: string,
        data: {
          canCreateCardForSelf: boolean;
          maxSpendLimitPerCard: Money;
          organizationId: string;
        }
      ) {
        return request<MemberPermissions>(`/members/${id}/permissions`, {
          method: 'put',
          data,
        });
      },
      getMonthlyOrgEmissionsByYear(id: string, params: object) {
        return request<MonthlyOrgEmissionsByYear>(
          `/organization-emissions/${id}/monthly`,
          { params }
        );
      },
      getCardCountsAndLimits(params: GetCardCountsAndLimitsParams) {
        return request<{ countsAndLimits: CardCountAndLimitByConfigMap }>(
          '/cards/counts-and-limits',
          { params }
        );
      },
      getAccountingSystems(organizationId: string) {
        return request<AccountingSystemList>(
          '/accounting/available-accounting-systems',
          { params: { organizationId } }
        );
      },
      getAccountingSettings(organizationId: string) {
        return request<AccountingSettings>('/accounting/settings', {
          params: { organizationId },
        });
      },
      updateAccountingSettings(data: UpdateAccountingSettingsPayload) {
        return request<AccountingSettings>('/accounting/settings', {
          method: 'put',
          data,
        });
      },
      updateMandatoryExportSettings(
        data: UpdateMandatoryExportSettingsPayload
      ) {
        return request<AccountingSettings>(
          '/accounting/settings/export/mandatory',
          {
            method: 'put',
            data,
          }
        );
      },
      updateAccountingGeneralSettings(
        data: UpdateAccountingGeneralSettingsPayload
      ) {
        return request<AccountingSettings>(
          '/accounting/settings/export/general',
          { method: 'put', data }
        );
      },
      updateAccountingGeneralAccounts(
        data: UpdateAccountingGeneralAccountsPayload
      ) {
        return request<AccountingSettings>(
          '/accounting/settings/export/accounts',
          { method: 'put', data }
        );
      },
      setAccountingSystem(
        organizationId: string,
        accountingSystem: AccountingSystem,
        accountingSystemName: string | null
      ) {
        return request<AccountingSettings>(
          '/accounting/settings/accounting-system',
          {
            method: 'put',
            data: { organizationId, accountingSystem, accountingSystemName },
          }
        );
      },
      getCardAccountsSettings(params: GetCardAccountsSettingsParams) {
        return request<CardAccountsSettingsPageableList>(
          '/accounting/card-account-settings',
          {
            params,
          }
        );
      },
      updateCardAccountSettings(
        cardAccountId: string,
        data: UpdateCardAccountSettingsPayload
      ) {
        return request<
          Pick<CardAccountSettings, 'cardAccountId' | 'pliantCreditCardAccount'>
        >(`/accounting/card-account-settings/${cardAccountId}`, {
          method: 'put',
          data,
        });
      },
      updateDatevAccountingSettings(
        data: UpdateDatevAccountingSettingsPayload
      ) {
        return request<AccountingSettings>('/accounting/settings/datev', {
          method: 'put',
          data,
        });
      },
      getDatevAuthRequest(organizationId: string) {
        return request<{ url: string }>('/datev/authrequest', {
          params: { organizationId },
        });
      },
      getDatevAuthRequestOffline(
        organizationId: string,
        datevClientId: string
      ) {
        return request<{ url: string }>('/datev/authrequest-offline', {
          params: { organizationId, datevClientId },
        });
      },
      getDatevConnect(params: object) {
        return request<DatevClientList>('/datev/connect', {
          params,
        });
      },
      validateDatevConnections(organizationId: string) {
        return request<DatevConnection>('/datev/validate-connection', {
          params: { organizationId },
        });
      },
      deleteDatevConnection(organizationId: string) {
        return request<void>('/datev/disconnect', {
          method: 'delete',
          params: { organizationId },
        });
      },
      getDatevErrorsAndAudit(organizationId: string) {
        return request<DatevConnection>('/datev/api-integration-errors', {
          params: { organizationId },
        });
      },
      getDatevExportTransactionStatus(
        organizationId: string,
        exportId: string
      ) {
        return request<DatevExportTransactions>(
          `/datev/export/organizations/${organizationId}/status/${exportId}`
        );
      },
      cleanupDatevConnection(organizationId: string) {
        return request<void>('/datev/cleanup', {
          method: 'delete',
          params: { organizationId },
        });
      },
      deleteDatevExporting(id: string, exportId: string) {
        return request(`/datev/export/organizations/${id}/delete/${exportId}`, {
          method: 'delete',
        });
      },
      getSubcategories(organizationId: string) {
        return request<SubcategoryPageableList>('/accounting/subcategories', {
          params: { organizationId },
        });
      },
      addSubcategory(data: Omit<Subcategory, 'id'>) {
        return request<Subcategory>('/accounting/subcategories', {
          method: 'post',
          data,
        });
      },
      updateSubcategory(data: Subcategory) {
        return request<Subcategory>(`/accounting/subcategories/${data.id}`, {
          method: 'put',
          data,
        });
      },
      deleteSubcategory(id: string) {
        return request(`/accounting/subcategories/${id}`, { method: 'delete' });
      },
      getVatRates(organizationId: string) {
        return request<VatRatePageableList>('/accounting/vat-rates', {
          params: { organizationId },
        });
      },
      addVatRate(data: Omit<VatRate, 'id'>) {
        return request<VatRate>('/accounting/vat-rates', {
          method: 'post',
          data,
        });
      },
      updateVatRate(data: VatRate) {
        return request<VatRate>(`/accounting/vat-rates/${data.id}`, {
          method: 'put',
          data,
        });
      },
      deleteVatRate(id: string) {
        return request(`/accounting/vat-rates/${id}`, { method: 'delete' });
      },
      getProjects(params: GetProjectsParams) {
        return request<ProjectPageableList>('/accounting/projects', {
          params,
        });
      },
      getCardholdersProjects(params: GetCardholderProjectsParams) {
        return request<ProjectPageableList>(
          '/accounting/projects/available/paginated',
          {
            params,
          }
        );
      },
      addProject(data: AddProjectPayload) {
        return request<Project>('/accounting/projects', {
          method: 'post',
          data,
        });
      },
      updateProject(id: string, data: UpdateProjectPayload) {
        return request<Project>(`/accounting/projects/${id}`, {
          method: 'put',
          data,
        });
      },
      deleteProject(id: string) {
        return request<Project>(`/accounting/projects/${id}`, {
          method: 'delete',
        });
      },
      getSuppliers(params: GetSuppliersParams) {
        return request<SupplierPageableList>('/suppliers', {
          params,
        });
      },
      addSupplier(data: AddSupplierPayload) {
        return request<Supplier>('/suppliers', {
          method: 'post',
          data,
        });
      },
      updateSupplier(supplierId: string, data: UpdateSupplierPayload) {
        return request<Supplier>(`/suppliers/${supplierId}`, {
          method: 'put',
          data,
        });
      },
      assignSupplier(data: AssignSupplierPayload) {
        return request<SupplierDetails>(`/suppliers/assign`, {
          method: 'post',
          data,
        });
      },
      unAssignSupplier(data: UnAssignSupplierPayload) {
        return request<void>(`/suppliers/assign`, {
          method: 'delete',
          data,
        });
      },
      deleteSupplier(supplierId: string) {
        return request<void>(`/suppliers/${supplierId}`, {
          method: 'delete',
        });
      },
      uploadSuppliers(
        organizationId: string,
        file: File,
        params: UploadSupplierParams,
        onUploadProgress: (percent: number) => void
      ) {
        const data = new FormData();
        data.append('file', file);
        data.append('organizationId', organizationId);
        return request<SupplierList>(`/suppliers/upload/${organizationId}`, {
          method: 'post',
          headers: { 'Content-Type': 'multipart/form-data' },
          data,
          params,
          onUploadProgress(progressEvent) {
            const { loaded, total } = progressEvent;
            if (total) {
              const percent = Math.round((loaded * 100) / total);
              onUploadProgress(percent);
            }
          },
        });
      },
      validateSuppliers(
        organizationId: string,
        file: File,
        params: UploadSupplierParams
      ) {
        const data = new FormData();
        data.append('file', file);
        data.append('organizationId', organizationId);
        return request<BulkUploadError[]>(
          `/suppliers/upload/${organizationId}/validate`,
          {
            method: 'post',
            headers: { 'Content-Type': 'multipart/form-data' },
            data,
            params,
          }
        );
      },
      uploadGlAccounts(
        organizationId: string,
        file: File,
        params: UploadGlAccountParams,
        onUploadProgress: (percent: number) => void
      ) {
        const data = new FormData();
        data.append('file', file);
        data.append('organizationId', organizationId);
        return request<SubcategoryUploadList>(
          `/accounting/subcategories/upload/${organizationId}`,
          {
            method: 'post',
            headers: { 'Content-Type': 'multipart/form-data' },
            data,
            params,
            onUploadProgress(progressEvent) {
              const { loaded, total } = progressEvent;
              if (total) {
                const percent = Math.round((loaded * 100) / total);
                onUploadProgress(percent);
              }
            },
          }
        );
      },
      validateGlAccounts(
        organizationId: string,
        file: File,
        params: UploadGlAccountParams
      ) {
        const data = new FormData();
        data.append('file', file);
        data.append('organizationId', organizationId);
        return request<BulkUploadError[]>(
          `/accounting/subcategories/upload/${organizationId}/validate`,
          {
            method: 'post',
            headers: { 'Content-Type': 'multipart/form-data' },
            data,
            params,
          }
        );
      },
      uploadProjects(
        organizationId: string,
        file: File,
        params: UploadProjectParams,
        onUploadProgress: (percent: number) => void
      ) {
        const data = new FormData();
        data.append('file', file);
        data.append('organizationId', organizationId);
        return request<ProjectUploadList>(
          `/accounting/projects/upload/${organizationId}`,
          {
            method: 'post',
            headers: { 'Content-Type': 'multipart/form-data' },
            data,
            params,
            onUploadProgress(progressEvent) {
              const { loaded, total } = progressEvent;
              if (total) {
                const percent = Math.round((loaded * 100) / total);
                onUploadProgress(percent);
              }
            },
          }
        );
      },
      validateProjects(
        organizationId: string,
        file: File,
        params: UploadProjectParams
      ) {
        const data = new FormData();
        data.append('file', file);
        data.append('organizationId', organizationId);
        return request<BulkUploadError[]>(
          `/accounting/projects/upload/${organizationId}/validate`,
          {
            method: 'post',
            headers: { 'Content-Type': 'multipart/form-data' },
            data,
            params,
          }
        );
      },
      assignSupplierToMerchant(
        organizationId: string,
        supplierId: string,
        merchantId: string
      ) {
        return request<void>('/suppliers/assign-merchant', {
          method: 'post',
          data: { organizationId, supplierId, merchantId },
        });
      },
      unassignSupplierFromMerchant(
        organizationId: string,
        supplierId: string,
        merchantId: string
      ) {
        return request<void>('/suppliers/assign-merchant', {
          method: 'delete',
          data: { organizationId, supplierId, merchantId },
        });
      },
      assignSubcategoryToMerchant(
        organizationId: string,
        subcategoryId: string,
        merchantId: string
      ) {
        return request<{ merchangId: string; subcategoryId: string }>(
          '/accounting/subcategories/assign-merchant',
          {
            method: 'post',
            data: { organizationId, subcategoryId, merchantId },
          }
        );
      },
      unassignSubcategoryFromMerchant(
        organizationId: string,
        subcategoryId: string,
        merchantId: string
      ) {
        return request('/accounting/subcategories/assign-merchant', {
          method: 'delete',
          data: { organizationId, subcategoryId, merchantId },
        });
      },
      assignVatRateToMerchant(
        organizationId: string,
        vatRateId: string,
        merchantId: string
      ) {
        return request<{ merchantId: string; vatRateId: string }>(
          '/accounting/vat-rates/assign-merchant',
          {
            method: 'post',
            data: { organizationId, vatRateId, merchantId },
          }
        );
      },
      unassignVatRateFromMerchant(
        organizationId: string,
        vatRateId: string,
        merchantId: string
      ) {
        return request('/accounting/vat-rates/assign-merchant', {
          method: 'delete',
          data: { organizationId, vatRateId, merchantId },
        });
      },
      getPartnerDeals(lang: string) {
        return request<PartnerDeal[]>(`/partner-deals`, {
          headers: {
            'Accept-Language': lang,
          },
        });
      },
      addCustomCashback(
        id: string,
        data: {
          cardAccountId: string;
          customCashbackAmount: Money;
          comment: string;
        }
      ) {
        return request(`/organization-cashback/${id}/custom-cashback`, {
          method: 'post',
          data,
        });
      },
      updateTradeName(organizationId: string, tradeName: string | null) {
        return request<Organization>(
          `/organizations/${organizationId}/trade-name`,
          {
            method: 'put',
            data: { tradeName },
          }
        );
      },
      updateVatId(
        organizationId: string,
        data: { vatId: string; vatIdRequired: boolean }
      ) {
        return request<Organization>(
          `/organizations/${organizationId}/vat-id`,
          {
            method: 'put',
            data,
          }
        );
      },
      getOrgMerchants(
        organizationId: string,
        params: GetOrganizationMerchantsParams
      ) {
        return request<OrganizationMerchantPageableList>(
          `/export/organizations/${organizationId}/merchants`,
          {
            params,
          }
        );
      },
      getOrgMerchant(organizationId: string, merchantId: string) {
        return request<OrganizationMerchant>(
          `/export/organizations/${organizationId}/merchants/${merchantId}`
        );
      },
      getOrgMerchantVolume(organizationId: string, merchantId: string) {
        return request<MerchantVolumeItemList>(
          `/export/organizations/${organizationId}/merchants/${merchantId}/chart_data`,
          { params: { months: 12 } }
        );
      },
      getMerchants(params: GetMerchantsParams) {
        return request<MerchantPageableList>(`/merchants`, {
          params,
        });
      },
      getMerchantDetails(id: string) {
        return request<MerchantDetails>(`/merchants/${id}`);
      },
      updateMerchant(id: string, data: UpdateMerchantPayload) {
        return request<MerchantDetails>(`/merchants/${id}`, {
          method: 'put',
          data,
        });
      },
      uploadMerchantLogo(merchantId: string, data: FormData) {
        return request<MerchantDetails>(`/merchants/${merchantId}/logo`, {
          method: 'post',
          headers: { 'Content-Type': 'multipart/form-data' },
          data,
        });
      },
      deleteMerchantLogo(merchantId: string) {
        return request<void>(`/merchants/${merchantId}/logo`, {
          method: 'delete',
        });
      },
      updateMerchantStatus(id: string, status: MerchantStatus) {
        return request<MerchantDetails>(`/merchants/${id}/status`, {
          method: 'put',
          data: { status },
        });
      },
      updateMerchantDescriptionSource(
        id: string,
        descriptionSource: MerchantDescriptionSource
      ) {
        return request<MerchantDetails>(`/merchants/${id}/description-source`, {
          method: 'post',
          data: { descriptionSource },
        });
      },
      mergeMerchants(srcMerchantId: string, targetMerchantId: string) {
        return request<MerchantDetails>(
          `/merchants/${srcMerchantId}/merge_with/${targetMerchantId}`,
          { method: 'post' }
        );
      },
      unmergeMerchants(
        srcMerchantId: string,
        data: { merchantIdsDe42: string[] }
      ) {
        return request<MerchantDetails>(`/merchants/${srcMerchantId}/unmerge`, {
          method: 'post',
          data,
        });
      },
      getSplitMerchantDetails(merchantId: string, matchingStrings: string) {
        return request<SplitMerchantDetails>(
          `/merchants/${merchantId}/split-details`,
          { params: { matchingStrings } }
        );
      },
      splitMerchant(merchantId: string, matchingStrings: string) {
        return request<MerchantDetails>(`/merchants/${merchantId}/split`, {
          method: 'post',
          data: { matchingStrings },
        });
      },
      unsplitMerchant(merchantId: string) {
        return request<void>(`/merchants/${merchantId}/unsplit`, {
          method: 'post',
        });
      },
      getForwardingIntegration(id: string) {
        return request<ForwardingIntegrations>(
          `/integrations/forwarding/organizations/${id}`
        );
      },
      updateForwardingIntegration(
        id: string,
        data: {
          forwardingEmail: string;
          status: ForwardingIntegrationStatus;
          forwardingFrequency?: ForwardingIntegrationFrequency;
          includeCreditCardStatement?: boolean;
          includeTransactionDetails?: boolean;
          detailedEmailSubject?: boolean;
        }
      ) {
        return request<ForwardingIntegrations>(
          `/integrations/forwarding/organizations/${id}`,
          {
            method: 'patch',
            data,
          }
        );
      },
      getReceiptsSettings(organizationId: string) {
        return request<ReceiptsSettings>(
          `/receipts/settings/${organizationId}`
        );
      },
      updateReceiptsSettings(
        organizationId: string,
        data: UpdateReceiptSettingsPayload
      ) {
        return request<ReceiptsSettings>(
          `/receipts/settings/${organizationId}`,
          {
            method: 'put',
            data,
          }
        );
      },
      updateCirculaSettings(id: string, data: UpdateCirculaSettingsPayload) {
        return request<SyncSettings>(
          `/integrations/sync-settings/organizations/${id}`,
          {
            method: 'patch',
            data,
          }
        );
      },
      resyncCirculaIntegration(data: { transactionIds: string[] }) {
        return request(`/integrations/transactionSync`, {
          method: 'patch',
          data,
        });
      },
      toggleOmrFeature(id: string, enabled: boolean) {
        return request<Organization>(
          `/organizations/${id}/toggle-omr-feature`,
          {
            method: 'put',
            data: {
              enabled,
            },
          }
        );
      },
      getPartners(params?: { page: number; limit: number }) {
        return request<PartnerPageableList>('/partners', {
          params,
        });
      },
      getPartnerOrgAuthDetails(
        partnerId: PartnerIdType,
        organizationId: string
      ) {
        return request<PartnerOrgAuthDetails>(
          `/integrations/partners/${partnerId}/organizations/${organizationId}`
        );
      },
      getPartnerConfig(partnerId: PartnerIdType) {
        return request<PartnerConfig>(`/partners/${partnerId}`);
      },
      authorizePartner(
        partnerId: PartnerIdType,
        organizationId: string,
        shareOnboardingStatus?: boolean
      ) {
        return request<PartnerOrgAuthDetails>(
          `/integrations/partners/${partnerId}/organizations`,
          {
            method: 'post',
            data: { organizationId, shareOnboardingStatus },
          }
        );
      },
      unAuthorizePartner(partnerId: PartnerIdType, organizationId: string) {
        return request(
          `/integrations/partners/${partnerId}/organizations/${organizationId}`,
          {
            method: 'delete',
          }
        );
      },
      changePartnerStatus(
        partnerId: PartnerIdType,
        organizationId: string,
        data: { status: PartnerAuthStatus }
      ) {
        return request<PartnerOrgAuthDetails>(
          `/integrations/partners/${partnerId}/organizations/${organizationId}/status`,
          {
            method: 'put',
            data,
          }
        );
      },
      changeShareOnboardingStatus(
        partnerId: PartnerIdType,
        organizationId: string,
        shareOnboardingStatus: boolean
      ) {
        return request<PartnerOrgAuthDetails>(
          `/integrations/partners/${partnerId}/organizations/${organizationId}/sharing`,
          { method: 'put', data: { shareOnboardingStatus } }
        );
      },
      getPartnerAudit(organizationId: string) {
        return request<PartnerAudit>(
          `/organizations/${organizationId}/partner/audit`
        );
      },
      changePartner(
        organizationId: string,
        data: {
          partnerId: PartnerIdType;
          partnerRevenueShareId: string;
        }
      ) {
        return request<PartnerAudit>(
          `/organizations/${organizationId}/partner`,
          {
            method: 'put',
            data,
          }
        );
      },
      changePartnerOrganizationId(
        organizationId: string,
        data: { newPartnersOrganizationId: string }
      ) {
        return request<PartnerAudit>(
          `/organizations/${organizationId}/partner/organization-id`,
          {
            method: 'put',
            data,
          }
        );
      },
      getOrganizationPartners(organizationId: string) {
        return request<{ data: OrganizationPartner[] }>(
          `integrations/organizations/${organizationId}/partners`
        );
      },
      updateCardVelocityGroup(
        cardId: string,
        data: { velocityLimitGroup: CardVelocityGroupLimits }
      ) {
        return request<Card>(`/cards/${cardId}/velocity-limit-group`, {
          method: 'post',
          data,
        });
      },
      getLocoiaEmbedSettings(params?: GetLocoiaEmbedSettingsParams) {
        return request<LocoiaEmbedSettingsPageableList>(
          '/locoia/embed-settings',
          { params }
        );
      },
      getLocoiaGeneratedJwt(locoiaEmbedId: string) {
        return request<LocoiaGeneratedJwt>(
          `/locoia/embed-settings/${locoiaEmbedId}/jwt`
        );
      },
      connectCodat(organizationId: string, data: { accountingSystem: string }) {
        return request<CodatData>(`/codat/connect/${organizationId}`, {
          method: 'post',
          data,
        });
      },
      disconnectCodat(organizationId: string) {
        return request<CodatData>(`/codat/disconnect/${organizationId}`, {
          method: 'delete',
        });
      },
      getCodatDataRefreshed(organizationId: string) {
        return request<CodatDataRefreshed>(`/codat/refresh-data-connections`, {
          params: { organizationId },
        });
      },
      getCodatMappingOptionsSummary(organizationId: string) {
        return request<CodatMappingOptionsSummary>(
          '/codat/get-mapping-options',
          {
            params: { organizationId },
          }
        );
      },
      getSyncedCodatMappingOptionsSummary(organizationId: string) {
        return request<CodatMappingOptionsSummary>(
          '/codat/sync-mapping-options',
          {
            params: { organizationId },
          }
        );
      },
      getCodatAccounts(organizationId: string) {
        return request<CodatAccountItem[]>('/codat/accounts', {
          params: { organizationId },
        });
      },
      updateCodatAccounts(data: UpdateCodatAccountsPayload) {
        return request<void>('/codat/accounts', {
          method: 'put',
          data,
        });
      },
      mapCodatSubcategories(data: MappedCodatSubcategoriesPayload) {
        return request<void>('/accounting/subcategories/codat', {
          method: 'post',
          data,
        });
      },
      getCodatTaxRates(organizationId: string) {
        return request<CodatTaxRateItem[]>('/codat/tax-rates', {
          params: { organizationId },
        });
      },
      updateCodatTaxRates(data: UpdateCodatTaxRatesPayload) {
        return request<void>('/codat/tax-rates', {
          method: 'put',
          data,
        });
      },
      mapCodatTaxRates(data: MappedCodatTaxRatesPayload) {
        return request<void>('/accounting/tax-rates/codat', {
          method: 'post',
          data,
        });
      },
      mapCodatSuppliers(data: MappedCodatSuppliersPayload) {
        return request<void>('/accounting/suppliers/codat', {
          method: 'post',
          data,
        });
      },
      getCodatSuppliers(organizationId: string) {
        return request<CodatSupplierItem[]>('/codat/suppliers', {
          params: { organizationId },
        });
      },
      updateCodatSuppliers(data: UpdateCodatSuppliersPayload) {
        return request<void>('/codat/suppliers', {
          method: 'put',
          data,
        });
      },
      getCodatTrackingCategories(
        organizationId: string,
        mappingTarget?: CodatMappingTarget
      ) {
        return request<CodatTrackingCategoryItem[]>(
          '/codat/tracking-categories',
          {
            params: { organizationId, mappingTarget },
          }
        );
      },
      getCodatTrackingCategoriesForParent(
        organizationId: string,
        parentId: string
      ) {
        return request<CodatTrackingCategoryItem[]>(
          '/codat/tracking-categories-for-parent',
          {
            params: { organizationId, parentId },
          }
        );
      },
      updateCodatTrackingCategories(
        data: UpdateCodatTrackingCategoriesPayload
      ) {
        return request<void>('/codat/tracking-categories', {
          method: 'put',
          data,
        });
      },
      getCodatExpenseSettings(organizationId: string) {
        return request<CodatExpenseSettings>('/codat/expense-settings', {
          params: { organizationId },
        });
      },
      updateCodatExpenseSettings(
        organizationId: string,
        data: UpdateCodatExpenseSettingsPayload
      ) {
        return request<CodatExpenseSettings>(
          `/codat/expense-settings/${organizationId}`,
          {
            method: 'put',
            params: { organizationId },
            data,
          }
        );
      },
      mapCodatTeams(data: MappedCodatTeamsPayload) {
        return request<void>('/teams/codat', {
          method: 'post',
          data,
        });
      },
      mapCodatProjects(data: MappedCodatProjectsPayload) {
        return request<void>('/accounting/tracking-categories/codat', {
          method: 'post',
          data,
        });
      },
      mapCodatDropdownOptions(data: MappedCodatDropdownOptionsPayload) {
        return request<void>('/accounting/tracking-categories/codat/options', {
          method: 'post',
          data,
        });
      },
      getCodatCompanyBankAccounts(
        organizationId: string,
        params: GetCodatCompanyBankAccountsParams
      ) {
        return request<CodatCompanyBankAccountsPageableList>(
          `/codat/company/${organizationId}/bank-accounts`,
          { params }
        );
      },
      getCodatCompanySuppliers(
        organizationId: string,
        params: GetCodatCompanySuppliersParams
      ) {
        return request<CodatCompanySuppliersPageableList>(
          `/codat/company/${organizationId}/suppliers`,
          { params }
        );
      },
      getCodatCompanyCustomers(
        organizationId: string,
        params: GetCodatCompanyCustomersParams
      ) {
        return request<CodatCompanyCustomersPageableList>(
          `/codat/company/${organizationId}/customers`,
          { params }
        );
      },
      getCodatCompanyConfig(organizationId: string) {
        return request<CodatCompanyConfig>(
          `/codat/company/${organizationId}/config`
        );
      },
      updateCodatCompanyConfig(
        organizationId: string,
        data: UpdateCodatCompanyConfigData
      ) {
        return request<CodatCompanyConfig>(
          `/codat/company/${organizationId}/config`,
          { method: 'POST', data }
        );
      },
      updateCodatReceipts(transactionId: string) {
        return request<void>(`/codat/expense/upload-receipt/${transactionId}`, {
          method: 'put',
        });
      },
      getAllPlans() {
        return request<any>(`/subscriptions`);
      },
      getAvailablePlans(organizationId: string) {
        return request<any>(
          `/subscriptions/${organizationId}/valid-target-plans`
        );
      },
      getSubscriptionPlanParams(organizationId: string) {
        return request<SubscriptionPlan>(`/subscriptions/${organizationId}`);
      },
      updatePlan(organizationId: string, data: { type: SubscriptionPlanType }) {
        return request<SubscriptionPlan>(
          `/subscriptions/${organizationId}/plan`,
          {
            method: 'PUT',
            data,
          }
        );
      },
      updateMonthlyBaseFee(
        organizationId: string,
        data: { baseFee: Money | null }
      ) {
        return request<SubscriptionPlan>(
          `/subscriptions/${organizationId}/base-fee`,
          {
            method: 'PUT',
            data,
          }
        );
      },
      updateFreeUsers(
        organizationId: string,
        data: { freeMembersCount: number | null }
      ) {
        return request<SubscriptionPlan>(
          `/subscriptions/${organizationId}/free-users`,
          {
            method: 'PUT',
            data,
          }
        );
      },
      updateMaxUsers(
        organizationId: string,
        data: { maxActiveMembersCount: number | null }
      ) {
        return request<SubscriptionPlan>(
          `/subscriptions/${organizationId}/max-users`,
          {
            method: 'PUT',
            data,
          }
        );
      },
      updateAdditionalUserFee(
        organizationId: string,
        data: { additionalUserFee: Money | null }
      ) {
        return request<SubscriptionPlan>(
          `/subscriptions/${organizationId}/additional-user-fee`,
          {
            method: 'PUT',
            data,
          }
        );
      },
      updateMaxVirtualCards(
        organizationId: string,
        data: { maxVirtualCardsCount: number | null }
      ) {
        return request<SubscriptionPlan>(
          `/subscriptions/${organizationId}/max-virtual-cards`,
          {
            method: 'PUT',
            data,
          }
        );
      },
      updateMaxSingleUseCards(
        organizationId: string,
        data: { maxSingleUseCardsCount: number | null }
      ) {
        return request<SubscriptionPlan>(
          `/subscriptions/${organizationId}/max-single-use-cards`,
          {
            method: 'PUT',
            data,
          }
        );
      },
      updateFxFeePercentage(
        organizationId: string,
        data: { fxFeePercentage: number }
      ) {
        return request<SubscriptionPlan>(
          `/subscriptions/${organizationId}/update-fx-fee-percentage`,
          { method: 'PUT', data }
        );
      },
      getPremiumCardFeeTiers(organizationId: string) {
        return request<PremiumCardFeeTierList>('/black-card-fee-tiers', {
          params: { organizationId },
        });
      },
      getCurrentPremiumCardFeeTier(organizationId: string) {
        return request<PremiumCardFeeTier>(
          '/black-card-fee-tiers/current-tier',
          {
            params: { organizationId },
          }
        );
      },
      createPremiumCardFeeTier(data: {
        organizationId: string;
        fee: Money;
        totalCardsCount: number;
      }) {
        return request<PremiumCardFeeTier>('/black-card-fee-tiers', {
          method: 'POST',
          data,
        });
      },
      updatePremiumCardFeeTier(
        id: string,
        data: { fee: Money; totalCardsCount: number | null }
      ) {
        return request<PremiumCardFeeTier>(`/black-card-fee-tiers/${id}`, {
          method: 'put',
          data,
        });
      },
      deletePremiumCardFeeTier(id: string) {
        return request<PremiumCardFeeTierList>(`/black-card-fee-tiers/${id}`, {
          method: 'delete',
        });
      },
      getPremiumCardLatestInvoice(cardId: string) {
        return request<PremiumCardInvoice>(`/stripe/${cardId}/latest-invoice`);
      },
      updatePremiumFeaturesFlag(
        organizationId: string,
        premiumFeaturesEnabled: boolean
      ) {
        return request<SubscriptionPlan>(
          `/subscriptions/${organizationId}/update-premium-features-flag`,
          {
            method: 'PUT',
            data: { premiumFeaturesEnabled },
          }
        );
      },
      updateInsuranceFlag(organizationId: string, insuranceEnabled: boolean) {
        return request<SubscriptionPlan>(
          `/subscriptions/${organizationId}/update-insurance-flag`,
          {
            method: 'PUT',
            data: { insuranceEnabled },
          }
        );
      },
      lockCard(cardId: string) {
        return request<Card>(`/cards/${cardId}/lock`, {
          method: 'post',
        });
      },
      lockPlatformFeeCard(cardId: string) {
        return request<Card>(`/cards/platform-fee/${cardId}/lock`, {
          method: 'post',
        });
      },
      unlockCard(cardId: string) {
        return request<Card>(`/cards/${cardId}/unlock`, {
          method: 'post',
        });
      },
      unlockPlatformFeeCard(cardId: string) {
        return request<Card>(`/cards/platform-fee/${cardId}/unlock`, {
          method: 'post',
        });
      },
      getFxRates(params: GetFxRatesParams) {
        return request<FxRateList>('/currency/fx-rates', { params });
      },
      /**@deprecated */
      getFxRatesDEPRECATED() {
        return request<FxRateListDEPRECATED>('/fx-rates');
      },
      getFxFeeAndVolume({
        organizationId,
        ...params
      }: GetFxFeeAndVolumeParams) {
        return request<FxFeeAndVolume>(`/export/fx-fees/${organizationId}`, {
          params,
        });
      },
      getOpenActiveOptIn() {
        return request<ActiveOptInRequest>(
          '/organizations/compliance/active-opt-in/open'
        );
      },
      acceptOpenActiveOptIn(organizationId: string, type: string) {
        return request<void>(
          `/organizations/${organizationId}/compliance/${type}/accept/active-opt-in`,
          {
            method: 'post',
          }
        );
      },
      getExistingActiveOptIn(
        documentType: DocumentType,
        country: string | undefined,
        partnerId: string | undefined
      ) {
        return request<ExistingActiveOptInRequest>(
          `/organizations/compliance/documents/${documentType}/exists-active-opt-in`,
          {
            params: { country, partnerId },
          }
        );
      },
      updateComplianceDocuments({
        type,
        option,
        version,
        file,
        country,
        partnerId,
      }: UpdateComplianceDocumentsPayload) {
        const data = new FormData();
        data.append('file', file);

        return request<void>('/documents/compliance', {
          method: 'post',
          headers: { 'Content-Type': 'multipart/form-data' },
          params: { type, option, version, country, partnerId },
          data,
        });
      },
      getMerchantCategoryCodeSearch(mccGroupCode: string, query?: string) {
        return request<{ mccList: MccCategory[] }>(
          `/merchant-category-code-search`,
          {
            params: { mccGroupCode, query },
          }
        );
      },
      getTimezones() {
        return request<Timezone[]>('/times/timezones');
      },
      getLatestBulkUploadStatus(
        context: 'members' | 'teams' | 'cards',
        organizationId: string,
        cardAccountId: string
      ) {
        return request<LatestXslxUploadResponse>(
          `/${context}/bulk-upload/latest`,
          {
            params: {
              organizationId:
                context === 'members' || context === 'teams'
                  ? organizationId
                  : null,
              cardAccountId: context === 'cards' ? cardAccountId : null,
            },
          }
        );
      },
      validateXlsxFile(
        context: 'members' | 'teams' | 'cards',
        organizationId: string,
        cardAccountId: string,
        file: File
      ) {
        const data = new FormData();
        data.append('file', file);
        return request<XlsxUploadResponse>(`/${context}/bulk-upload/validate`, {
          method: 'post',
          headers: { 'Content-Type': 'multipart/form-data' },
          params: {
            organizationId:
              context === 'members' || context === 'teams'
                ? organizationId
                : null,
            cardAccountId: context === 'cards' ? cardAccountId : null,
          },
          data,
        });
      },
      uploadItems(
        context: 'members' | 'teams' | 'cards',
        organizationId: string,
        cardAccountId: string,
        file: File
      ) {
        const data = new FormData();
        data.append('file', file);
        return request<XlsxUploadResponse>(`/${context}/bulk-upload`, {
          method: 'post',
          headers: { 'Content-Type': 'multipart/form-data' },
          params: {
            organizationId:
              context === 'members' || context === 'teams'
                ? organizationId
                : null,
            cardAccountId: context === 'cards' ? cardAccountId : null,
          },
          data,
        });
      },
      createAccTxnCustomFields(
        accountingTransactionId: string,
        data: UpdateAccTxnCustomFieldPayload
      ) {
        return request<UpdateAccTxnCustomFieldResponse>(
          `/accounting/acc-txn-custom-fields/${accountingTransactionId}`,
          {
            method: 'post',
            data,
          }
        );
      },
      updateAccTxnCustomFields(
        accountingTransactionId: string,
        data: UpdateAccTxnCustomFieldPayload
      ) {
        return request<UpdateAccTxnCustomFieldResponse>(
          `/accounting/acc-txn-custom-fields/${accountingTransactionId}`,
          {
            method: 'patch',
            data,
          }
        );
      },
      getCustomFields(params: GetCustomFieldsPayload) {
        return request<CustomFieldsPageableList>(
          '/accounting/transaction-custom-fields',
          {
            params,
          }
        );
      },
      addCustomField(data: ManageCustomFieldPayload) {
        return request<CustomField>('/accounting/transaction-custom-fields', {
          method: 'post',
          data,
        });
      },
      updateCustomField(id: string, data: ManageCustomFieldPayload) {
        return request<CustomField>(
          `/accounting/transaction-custom-fields/${id}`,
          {
            method: 'put',
            data,
          }
        );
      },
      deleteCustomField(id: string) {
        return request(`/accounting/transaction-custom-fields/${id}`, {
          method: 'delete',
        });
      },
      updateCardsCardTxCustomFields(
        data: UpdateCardsCardTxCustomFieldsPayload
      ) {
        return request<CardsCardTxCustomFieldsResponseList>(
          `/cards/card-transaction-custom-fields/batch`,
          { method: 'post', data }
        );
      },
      getCustomFieldOptions(params: GetCustomFieldOptionsPayload) {
        return request<CustomFieldOptionsPageableList>(
          '/accounting/transaction-custom-fields/options',
          { params }
        );
      },
      addCustomFieldOption(data: ManageCustomFieldOptionPayload) {
        return request<CustomFieldOption>(
          '/accounting/transaction-custom-fields/options',
          { method: 'post', data }
        );
      },
      editCustomFieldOption(
        optionId: string,
        data: ManageCustomFieldOptionPayload
      ) {
        return request<CustomFieldOption>(
          `/accounting/transaction-custom-fields/options/${optionId}`,
          { method: 'put', data }
        );
      },
      deleteCustomFieldOption(optionId: string) {
        return request<void>(
          `/accounting/transaction-custom-fields/options/${optionId}`,
          { method: 'delete' }
        );
      },
      getReceiptAutoMatchingCount(organizationId: string) {
        return request<{
          unmatchedTaskCount: number;
          unmatchedTaskCountSelf: number;
        }>(`/receipts-auto-matching/${organizationId}/count`);
      },
      getReceiptsAutoMatching(params: GetReceiptsAutoMatchingParams) {
        return request<ReceiptsAutoMatchingPageableList>(
          '/receipts-auto-matching',
          { params }
        );
      },
      getReceiptAutoMatchingDetails(taskId: string) {
        return request<ReceiptAutoMatchingDetails>(
          `/receipts-auto-matching/${taskId}/details`,
          { params: { taskId } }
        );
      },
      getReceiptsAutoMatchingThumbnails(taskIds: string) {
        return request<ReceiptsAutoMatchingThumbnailsList>(
          '/receipts-auto-matching/thumbnails',
          { params: { taskIds } }
        );
      },
      getReceiptsAutoMatchingAttachment(taskId: string) {
        return request<ReceiptsAutoMatchingAttachment>(
          `/receipts-auto-matching/${taskId}/attachment`,
          { params: { taskId } }
        );
      },
      getReceiptsAutoMatchingSameEmail(taskId: string, organizationId: string) {
        return request<ReceiptsAutoMatchingSameEmail[]>(
          `/receipts-auto-matching/${taskId}/sameEmail`,
          {
            params: { taskId, organizationId },
          }
        );
      },
      matchReceiptToTransaction(taskId: string, transactionId: string) {
        return request<void>('/receipts-auto-matching/matchTaskToTransaction', {
          method: 'post',
          data: { taskId, transactionId },
        });
      },
      unmatchReceiptFromTransaction(taskId: string, transactionId: string) {
        return request<void>(
          '/receipts-auto-matching/unmatchTaskToTransaction',
          {
            method: 'post',
            data: { taskId, transactionId },
          }
        );
      },
      deleteReceiptsAutoMatchingTrash(
        data: {
          all?: boolean;
          taskIds?: string[];
        },
        memberId?: string,
        organizationId?: string
      ) {
        return request<void>('/receipts-auto-matching/emptyTrash', {
          method: 'post',
          params: { memberId, organizationId },
          data,
        });
      },
      deleteReceiptsAutoMatching(params: { taskId: string }) {
        return request<void>(`/receipts-auto-matching/${params.taskId}`, {
          method: 'delete',
          params,
        });
      },
    }),
    [request]
  );
};

export default useImperativeApi;
