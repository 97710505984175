import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import { DocumentLink } from 'domains/document/components';
import { ArchivedDocumentsDialog } from 'domains/document/dialogs';
import { getDocumentName, getDocumentUrl } from 'domains/document/utils';
import { Box, Button, Typography } from 'elements';
import useMounted from 'hooks/useMounted';
import useSnackbar from 'hooks/useSnackbar';
import { DocumentGenericType, TacMetadata } from 'services/constants';
import { logError } from 'services/monitoring';
import useImperativeApi from 'services/network/useImperativeApi';
import { useCanUser } from 'services/rbac';
import { getGenericErrorMsg } from 'services/utils';

const tacMetadataTypeByDocumentGenericType: Partial<Record<
  DocumentGenericType,
  string
>> = {
  [DocumentGenericType.issuerTermsAndConditions]: 'ISSUER',
  [DocumentGenericType.bankTermsAndConditions]: 'PARTNER_BANK',
  [DocumentGenericType.platformTermsAndConditions]: 'PLATFORM',
  [DocumentGenericType.insuranceTerms]: 'INSURANCE',
};

const OrganizationDocuments = () => {
  const { t } = useTranslation();
  const mounted = useMounted();
  const api = useImperativeApi();
  const { enqueueSnackbar } = useSnackbar();
  const canUser = useCanUser();
  const {
    state: { organization, documents, defaultCardAccount },
  } = useGlobalState();

  const [termsAndConditions, setTermsAndConditions] = useState<
    TacMetadata[] | null
  >(null);
  const [
    isArchivedDocumentsDialogOpen,
    setIsArchivedDocumentsDialogOpen,
  ] = useState(false);

  const getData = async () => {
    try {
      const { termsAndConditions } = await api.getTermsAndConditions(
        organization!.id
      );
      if (!mounted.current) return;
      setTermsAndConditions(termsAndConditions);
    } catch (error) {
      if (!mounted.current) return;
      enqueueSnackbar(getGenericErrorMsg(error), { variant: 'error' });
      logError(error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <Box mt={9}>
        <Typography variant="h6" mb={3}>
          {t('settingsOrganizationPage.termsAndConditions')}
        </Typography>

        <div>
          {documents ? (
            [
              DocumentGenericType.platformTermsAndConditions,
              DocumentGenericType.bankTermsAndConditions,
              DocumentGenericType.issuerTermsAndConditions,
              ...(canUser('insurance-conditions-document:view')
                ? [DocumentGenericType.insuranceTerms]
                : []),
            ].map((genericType) => {
              if (!documents[genericType]) return null;
              const doc = documents[genericType][0];
              return (
                <DocumentLink
                  key={doc.documentId}
                  name={getDocumentName(
                    doc,
                    genericType,
                    organization!.partnerId,
                    defaultCardAccount!.accountGroup.value
                  )}
                  url={getDocumentUrl(doc.documentName)}
                  metadata={termsAndConditions?.find(
                    (metadata) =>
                      metadata.type ===
                      tacMetadataTypeByDocumentGenericType[genericType]
                  )}
                />
              );
            })
          ) : (
            <Typography variant="body2" color="error.main">
              {t('errors.loadData')}
            </Typography>
          )}
        </div>
      </Box>

      <Box mt={4} mb={2}>
        <Typography variant="h6" mb={3}>
          {t('settingsOrganizationPage.dataPrivacyPolicies')}
        </Typography>

        <div>
          {documents ? (
            [
              DocumentGenericType.platformDataPrivacyPolicyWeb,
              DocumentGenericType.platformDataPrivacyPolicyMobile,
              DocumentGenericType.platformDataPrivacyPolicy,
              DocumentGenericType.bankDataPrivacyPolicy,
              DocumentGenericType.issuerDataPrivacyPolicy,
            ].map((genericType) => {
              if (!documents[genericType]) return null;
              const doc = documents[genericType][0];
              return (
                <DocumentLink
                  key={doc.documentId}
                  name={getDocumentName(
                    doc,
                    genericType,
                    organization!.partnerId,
                    defaultCardAccount!.accountGroup.value
                  )}
                  url={getDocumentUrl(doc.documentName)}
                />
              );
            })
          ) : (
            <Typography variant="body2" color="error.main">
              {t('errors.loadData')}
            </Typography>
          )}
        </div>
      </Box>

      <Button
        variant="text"
        onClick={() => setIsArchivedDocumentsDialogOpen(true)}
      >
        {t('settingsOrganizationPage.archivedDocuments')}
      </Button>

      <ArchivedDocumentsDialog
        open={isArchivedDocumentsDialogOpen}
        onClose={() => setIsArchivedDocumentsDialogOpen(false)}
      />
    </>
  );
};

export default OrganizationDocuments;
