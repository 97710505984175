import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGlobalState } from 'context/GlobalState';
import { OrganizationStatusBadge } from 'domains/organization/components';
import { QueryParams } from 'domains/organization/pages/OrganizationsPage/OrganizationsAndNcoSubPage';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CaretDownIcon,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  MenuItem,
  Select,
  StatusBadge,
  Typography,
} from 'elements';
import { SetQueryParam } from 'hooks/useSetQueryParam';
import { FilterDrawer, FilterDrawerProps } from 'layout';
import {
  AccountGroup,
  accountGroups,
  CardAccountCurrency,
  OrganizationStatus,
  organizationStatuses,
  Partner,
  PartnerStatus,
  supportedCountries,
} from 'services/constants';

// We have two onboarding risk statuses: onboardingRiskData and onboardingRiskReview.
// There must be only one option in the filter select but when a user clicks on it,
// both statuses are fetched. That's why we filter out onboardingRiskData and add it back
// if onboardingRiskReview is selected (see the getStatusApiParam functions).
// - rm nonCustomer status in OrganzationsPage status filter
const filteredOrgStatuses = organizationStatuses.filter(
  (status) =>
    ![
      OrganizationStatus.onboardingRiskData,
      OrganizationStatus.nonCustomer,
    ].includes(status)
);

interface Props extends Omit<FilterDrawerProps, 'onSuccess' | 'onReset'> {
  params: QueryParams;
  setParam: SetQueryParam;
  onClose: () => void;
  isNCOPage: boolean;
  partners: Partner[];
}

interface State {
  status: string[];
  country: string;
  partnerConfigId: string;
  accountGroup: AccountGroup | '';
  currency: CardAccountCurrency | '';
}

type SetFilterArgs = {
  key: 'status';
  value: string;
  type: 'add' | 'remove';
};

const FiltersDrawer = ({
  params,
  setParam,
  isNCOPage,
  partners,
  ...props
}: Props) => {
  const { t } = useTranslation();
  const {
    state: { cardAccountCurrencies },
  } = useGlobalState();
  const [state, setState] = useState<State>({
    status: [],
    country: '',
    partnerConfigId: '',
    accountGroup: '',
    currency: '',
  });
  const areFiltersSelected = !!(
    state.status.length ||
    state.country ||
    state.partnerConfigId ||
    state.accountGroup ||
    state.currency
  );

  useEffect(() => {
    if (props.open)
      setState({
        status: params.status,
        country: params.country,
        partnerConfigId: params.partnerConfigId,
        accountGroup: params.accountGroup as State['accountGroup'],
        currency: params.currency as State['currency'],
      });
  }, [params, props.open]);

  const setFilters = ({ key, value, type }: SetFilterArgs) => {
    if (type === 'add')
      setState((prevState) => ({
        ...prevState,
        [key]: [...prevState[key], value],
      }));
    else
      setState((prevState) => ({
        ...prevState,
        [key]: prevState[key].filter((item) => item !== value),
      }));
  };

  const onFiltersApply = () => {
    setParam(Object.keys(state).map((key) => [key, state[key as keyof State]]));
    props.onClose();
  };

  const onFilterReset = () => {
    setParam(Object.keys(state).map((key) => [key, '']));
    props.onClose();
  };

  return (
    <FilterDrawer
      {...props}
      areFiltersSelected={areFiltersSelected}
      onSuccess={onFiltersApply}
      onReset={onFilterReset}
    >
      {!isNCOPage && (
        <Accordion defaultExpanded>
          <AccordionSummary
            expandIcon={<CaretDownIcon />}
            aria-controls="panel-role"
            id="panel-role-header"
          >
            <Typography>{t('filters.status')} </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <FormControl>
              <FormGroup>
                {filteredOrgStatuses.map((status) => (
                  <FormControlLabel
                    key={status}
                    checked={state.status.includes(status)}
                    control={<Checkbox />}
                    onChange={(_, checked) =>
                      setFilters({
                        key: 'status',
                        value: status,
                        type: checked ? 'add' : 'remove',
                      })
                    }
                    label={<OrganizationStatusBadge status={status} />}
                  />
                ))}
              </FormGroup>
            </FormControl>
          </AccordionDetails>
        </Accordion>
      )}

      {!isNCOPage && partners?.length > 0 && (
        <Accordion defaultExpanded>
          <AccordionSummary
            expandIcon={<CaretDownIcon />}
            aria-controls="panel-config"
            id="panel-config-header"
          >
            <Typography>{t('int.filters.config')}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <FormControl fullWidth>
              <Select
                size="medium"
                displayEmpty
                value={state.partnerConfigId}
                onChange={(e) =>
                  setState((prevState) => ({
                    ...prevState,
                    partnerConfigId: e.target.value,
                  }))
                }
                renderValue={(selected) => {
                  if (!selected)
                    return (
                      <Typography color="text.disabled">
                        {t('int.filters.config')}
                      </Typography>
                    );

                  const selectedPartner = partners?.find(
                    (item) => item.partnerId === selected
                  );
                  return selectedPartner!.name;
                }}
              >
                <MenuItem value="">
                  <Typography color="text.disabled">
                    {t('int.filters.config')}
                  </Typography>
                </MenuItem>
                {partners?.map((item) => (
                  <MenuItem
                    key={String(item.partnerId)}
                    value={item.partnerId as string}
                  >
                    {item.name.toUpperCase()}
                    {item.status === PartnerStatus.inactive && (
                      <StatusBadge
                        label={t('common.badge.archived')}
                        color="default"
                        sx={{ ml: 1 }}
                      />
                    )}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </AccordionDetails>
        </Accordion>
      )}

      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<CaretDownIcon />}
          aria-controls="panel-country"
          id="panel-country-header"
        >
          <Typography>{t('filters.country')}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <FormControl fullWidth>
            <Select
              size="medium"
              displayEmpty
              value={state.country}
              onChange={(e) =>
                setState((prevState) => ({
                  ...prevState,
                  country: e.target.value,
                }))
              }
              renderValue={(selected) => {
                if (!selected)
                  return (
                    <Typography color="text.disabled">
                      {t('filters.country')}
                    </Typography>
                  );
                return t(`countries.${selected}`);
              }}
            >
              <MenuItem value="">
                <Typography color="text.disabled">
                  {t('filters.country')}
                </Typography>
              </MenuItem>
              {supportedCountries.map((item) => (
                <MenuItem key={item} value={item}>
                  {t(`countries.${item}`)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </AccordionDetails>
      </Accordion>

      {!isNCOPage && (
        <Accordion defaultExpanded>
          <AccordionSummary
            expandIcon={<CaretDownIcon />}
            aria-controls="panel-card-account-currency"
            id="panel-card-account-currency-header"
          >
            <Typography>{t('int.filters.currency')}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <FormControl fullWidth>
              <Select
                size="medium"
                displayEmpty
                value={state.currency}
                onChange={(e) =>
                  setState((prevState) => ({
                    ...prevState,
                    currency: e.target.value as State['currency'],
                  }))
                }
                renderValue={(selected) => {
                  if (!selected)
                    return (
                      <Typography color="text.disabled">
                        {t('int.filters.currency')}
                      </Typography>
                    );
                  return selected;
                }}
              >
                <MenuItem value="">
                  <Typography color="text.disabled">
                    {t('int.filters.currency')}
                  </Typography>
                </MenuItem>
                {cardAccountCurrencies.map((item) => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </AccordionDetails>
        </Accordion>
      )}

      {!isNCOPage && (
        <Accordion defaultExpanded>
          <AccordionSummary
            expandIcon={<CaretDownIcon />}
            aria-controls="panel-account-group"
            id="panel-account-group-header"
          >
            <Typography>{t('int.filters.accountGroup')}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <FormControl fullWidth>
              <Select
                size="medium"
                displayEmpty
                value={state.accountGroup}
                onChange={(e) =>
                  setState((prevState) => ({
                    ...prevState,
                    accountGroup: e.target.value as State['accountGroup'],
                  }))
                }
                renderValue={(selected) => {
                  if (!selected)
                    return (
                      <Typography color="text.disabled">
                        {t('int.filters.accountGroup')}
                      </Typography>
                    );
                  return t(`int.accountGroupSelect.items.${selected}`);
                }}
              >
                <MenuItem value="">
                  <Typography color="text.disabled">
                    {t('int.filters.accountGroup')}
                  </Typography>
                </MenuItem>
                {accountGroups.map((item) => (
                  <MenuItem key={item} value={item}>
                    {t(`int.accountGroupSelect.items.${item}`)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </AccordionDetails>
        </Accordion>
      )}
    </FilterDrawer>
  );
};

export default FiltersDrawer;
