import { Trans, useTranslation } from 'react-i18next';
import {
  Box,
  CaretDownIcon,
  CaretUpIcon,
  Chip,
  Divider,
  DownloadSimpleIcon,
  IconButton,
  MagnifyingGlassMinusIcon,
  MagnifyingGlassPlusIcon,
  Typography,
  XIcon,
} from 'elements';
import { EReceiptType } from 'services/constants';
import { DEFAULT_SCALE } from './index';

export const SCALE_STEP = 0.15;

interface Props {
  isLoading: boolean;
  isAttachmentEmpty: boolean;
  fileName: string;
  ereceiptType: EReceiptType | null;
  page: number;
  numPages: number;
  scale: number;
  onChange: (newPage?: number, newScale?: number) => void;
  onDownload: () => void;
  onClose: () => void;
}

const PreviewDialogHeader = ({
  isLoading,
  isAttachmentEmpty,
  fileName,
  ereceiptType,
  page,
  numPages,
  scale,
  onChange,
  onDownload,
  onClose,
}: Props) => {
  const { t } = useTranslation();
  const scalePercent = Math.round((scale - DEFAULT_SCALE + 1) * 100);

  return (
    <>
      <Box display="flex" alignItems="center" height="60px" flexShrink={0}>
        <Box
          flex="1 1 auto"
          minWidth="100px"
          maxWidth="50%"
          pr={3}
          pl={2}
          display="flex"
          alignItems="center"
        >
          <IconButton onClick={onClose}>
            <XIcon />
          </IconButton>

          <Typography noWrap ml={2}>
            {fileName}
          </Typography>
        </Box>
        {ereceiptType && (
          <Chip
            sx={{ mr: 3 }}
            label={`${t('common.badge.einvoice')} (${ereceiptType})`}
          />
        )}
        <Box display="flex" alignItems="center">
          <IconButton
            size="small"
            sx={{ mr: 0.5 }}
            disabled={page <= 1 || numPages < 2 || isLoading}
            onClick={() => onChange(page - 1)}
          >
            <CaretUpIcon />
          </IconButton>
          <Typography
            variant="body2"
            noWrap
            sx={{ fontVariantNumeric: 'tabular-nums' }}
          >
            <Trans
              i18nKey="previewDialog.page"
              values={{ pagination: `${page} / ${numPages}` }}
            />
          </Typography>
          <IconButton
            size="small"
            sx={{ ml: 0.5 }}
            disabled={page >= numPages || numPages < 2 || isLoading}
            onClick={() => onChange(page + 1)}
          >
            <CaretDownIcon />
          </IconButton>
        </Box>

        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          textAlign="center"
          px={4}
        >
          <IconButton
            size="small"
            disabled={scalePercent <= 40 || isLoading}
            onClick={() =>
              onChange(undefined, parseFloat((scale - SCALE_STEP).toFixed(2)))
            }
          >
            <MagnifyingGlassMinusIcon />
          </IconButton>

          <Typography mx={0.5} noWrap>
            {scalePercent}%
          </Typography>

          <IconButton
            size="small"
            disabled={scalePercent >= 190 || isLoading}
            onClick={() =>
              onChange(undefined, parseFloat((scale + SCALE_STEP).toFixed(2)))
            }
          >
            <MagnifyingGlassPlusIcon />
          </IconButton>
        </Box>

        <Box display="flex" whiteSpace="nowrap" ml="auto" pr={1.5}>
          <IconButton
            onClick={onDownload}
            size="small"
            disabled={isAttachmentEmpty}
          >
            <DownloadSimpleIcon />
          </IconButton>
        </Box>
      </Box>
      <Divider />
    </>
  );
};

export default PreviewDialogHeader;
